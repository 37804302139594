<template>
  <div class="multi-camera-view">
    <eewc-drag-and-drop
      v-if="availableViews || connectedViews"
      :list="availableFilteredViews"
      :right-title="t('Connected views')"
      :left-title="t('Available views')"
      :value="connectedFilteredViews"
      :bulk-action="false"
      :no-search-result-text="t('No cameras found')"
      :placeholder-right="t('Click to move view here...')"
      :search-placeholders="{
        left: t('Filter for views'),
        right: t('Filter for views'),
      }"
      searchable
      paginated
      @infinite-left="displayAvailableViews"
      @infinite-right="displayConnectedViews"
      @search-left="leftSearchHandler"
      @search-right="rightSearchHandler"
      @data-changed="changeViews"
    />
    <slot name="cameraInfoBar" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

import _ from 'lodash';

import { t } from '@/plugins/i18n.ts';

import { useMultiCamerasStore } from '@/stores';
import { IntersectionStateChanger } from '@eencloud/eewc-components/src/utils/types';
import { Views } from './MultiCameraSettings.vue';

const props = defineProps<{
  bridgeId?: string;
  cameraId: string;
}>();

let availableViewsPageToken = '';
let connectedViewsPageToken = '';

const multiCamerasStore = useMultiCamerasStore();

const connectedViews = ref<Views[]>([]);
const availableViews = ref<Views[]>([]);
const filteredConnectedViews = ref<Views[]>([]);
const filteredAvailableViews = ref<Views[]>([]);
const viewsToRemove = ref<Views[]>();
const viewsToAdd = ref<Views[]>();
const rightSearchText = ref<string>('');
const leftSearchText = ref<string>('');

const availableFilteredViews = computed(() =>
  leftSearchText.value ? filteredAvailableViews.value : availableViews.value
);
const connectedFilteredViews = computed(() =>
  rightSearchText.value ? filteredConnectedViews.value : connectedViews.value
);

function displayAvailableViews(val: { search: string; state: IntersectionStateChanger }) {
  getPagedAvailableCameras(val.state);
}

function displayConnectedViews(val: { search: string; state: IntersectionStateChanger }) {
  getPagedChildCameras(val.state);
}

async function getPagedAvailableCameras(state?: IntersectionStateChanger) {
  const params = {
    pageSize: 50,
    pageToken: availableViewsPageToken,
  };

  const { results, totalSize, nextPageToken } = await multiCamerasStore.getMultiCameraChannels(props.cameraId, params);
  availableViewsPageToken = nextPageToken;

  const items = results
    ?.filter((result) => !result.cameraId)
    .map((result) => ({ text: `${result.channelName}`, value: result.guid, cameraId: result.cameraId } as Views));

  const allItems = [...availableViews.value, ...items];

  availableViews.value = _.uniqBy(allItems, 'value');

  state?.loaded();

  if (!availableViews.value.length || totalSize === availableViews.value.length || nextPageToken === '')
    state?.completed();
}

async function getPagedChildCameras(state?: IntersectionStateChanger) {
  const params = {
    pageSize: 50,
    pageToken: connectedViewsPageToken,
  };

  const { results, totalSize, nextPageToken } = await multiCamerasStore.getMultiCameraChannels(props.cameraId, params);
  connectedViewsPageToken = nextPageToken;

  const items = results
    ?.filter((result) => result.cameraId)
    .map((result) => ({ text: `${result.channelName}`, value: result.guid, cameraId: result.cameraId } as Views));

  const allItems = [...connectedViews.value, ...items];

  connectedViews.value = _.uniqBy(allItems, 'value');

  state?.loaded();

  if (!connectedViews.value.length || totalSize === connectedViews.value.length || nextPageToken === '')
    state?.completed();
}

function leftSearchHandler(val: { search: string; state: IntersectionStateChanger }) {
  leftSearchText.value = val.search.toLowerCase().trim();
  filteredAvailableViews.value = availableViews.value.filter((view) =>
    view.text.toLowerCase().includes(leftSearchText.value)
  );

  val.state.loaded();
}

function rightSearchHandler(val: { search: string; state: IntersectionStateChanger }) {
  rightSearchText.value = val.search.toLowerCase().trim();
  filteredConnectedViews.value = connectedViews.value.filter((item) =>
    item.text.toLowerCase().includes(rightSearchText.value)
  );

  val.state.loaded();
}

function changeViews(data: { added: Views[]; removed: Views[] }) {
  viewsToRemove.value = data.added;
  viewsToAdd.value = data.removed;
}

function isDirty() {
  return !!viewsToRemove.value || !!viewsToAdd.value;
}

function getAvailableViews() {
  return viewsToRemove.value;
}

function getConnectedViews() {
  return viewsToAdd.value;
}

const getUpdates = {
  availableViews: getAvailableViews,
  connectedViews: getConnectedViews,
};

defineExpose({ isDirty, getUpdates });
</script>

<style lang="scss" scoped>
@import '@/styles/public/main.scss';

.multi-camera-view {
  color: $primary;
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
}
</style>
