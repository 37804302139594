<template>
  <div class="notification-dialog">
    <v-menu
      v-model="customShow"
      :close-on-content-click="false"
      content-class="notification-dialog__content"
      offset-y
      offset-x
      nudge-left="-56"
      min-width="420"
      max-width="420"
      left
    >
      <template #activator="{ on, attrs }">
        <eewc-tooltip
          attach
          :text="newNotification ? $t('New notifications') : $t('Notifications')"
        >
          <template #content="{ on: tooltipOn, attrs: tooltipAttrs }">
            <div
              v-bind="tooltipAttrs"
              class="mx-5"
              :class="customShow ? 'notification-dialog__btn' : ''"
              v-on="tooltipOn"
            >
              <eewc-button-common
                data-testid="top-bar-btn-notification"
                v-bind="attrs"
                :append-icon="`${newNotification ? '$icon_notification_new' : '$icon_notification'}`"
                icon
                :icon-size="32"
                type="clear"
                v-on="on"
                @click="openDialog"
              />
            </div>
          </template>
        </eewc-tooltip>
      </template>
      <eewc-page-title
        :title="$t('Notifications')"
        class="ml-5 my-3 mr-2"
      >
        <template #action>
          <div
            v-if="unreadState || notificationList.length"
            class="notification-dialog__content--show-unread"
          >
            {{ $t('Show only new') }}
          </div>
          <eewc-switch-button
            v-if="unreadState || notificationList.length"
            id="viewId"
            v-model="unreadState"
            class="notification-dialog__content--switch"
            @input="showUnread"
          />
          <eewc-button-common
            type="clear"
            text
            color="accent"
            data-testid="notification-dialog-header-view-all-btn"
            @click="gotoNotifications"
          >
            {{ $t('View all') }}
          </eewc-button-common>
        </template>
      </eewc-page-title>
      <v-divider class="notification-dialog__content--divider" />
      <div
        v-if="notificationsStore.recentLoadingNotifications || notificationList.length || unreadState"
        class="notification-dialog__content--sub-header"
      >
        <span class="notification-dialog__content--sub-header-title">
          {{ $t('Latest') }}
        </span>
        <eewc-button-common
          type="clear"
          text
          :disabled="notificationList.length === 0"
          @click="dropDownMenuAction('bulkAction')"
        >
          {{ headerAction.unreadCount ? $t('Mark all as viewed') : $t('Mark all as new') }}
        </eewc-button-common>
      </div>
      <eewc-table
        v-if="notificationsStore.recentLoadingNotifications || notificationList.length"
        :previous-tooltip="$t('Previous page')"
        :more-tooltip="$t('More')"
        :next-tooltip="$t('Next page')"
        :loading="notificationsStore.recentLoadingNotifications"
        class="notification-dialog__content--table"
        :class="[
          {
            'notification-dialog__content--action-menu': sidebarNotifications.length,
          },
        ]"
        :data="notificationList.length ? tableNotifications : Array(3).fill({})"
        :is-row-clickable="true"
        @markRead="(item) => dropDownMenuAction('markRead', item)"
        @markUnread="(item) => dropDownMenuAction('markUnread', item)"
        @row-click="(item) => openHistoryBrowser(item)"
      >
        <template #row="{ item, selectRow }">
          <td class="notification">
            <div class="d-flex align-center notification--icon">
              <v-icon
                size="20"
                class="mr-2 ml-1"
              >
                {{ getNotificationIcon(item.category) }}
              </v-icon>
              <div>
                <eewc-tooltipped-text
                  :text="item.actorName"
                  :class="`${item.read ? 'notification__primary-text-read' : 'notification__primary-text-unread'}`"
                />
                <div
                  :class="`notification__secondary-text ${
                    item.read ? 'notification__secondary-text-read' : 'notification__secondary-text-unread'
                  }`"
                >
                  <eewc-tooltipped-text
                    :text="item.description.charAt(0).toUpperCase() + item.description.slice(1)"
                    :max-width="155"
                  />
                  <div class="pl-1 pr-1">•</div>
                  <eewc-tooltipped-text
                    :text="calculateAge(item.timestamp)"
                    :class="`${
                      item.read ? 'notification__secondary-text--age-read' : 'notification__secondary-text--age-unread'
                    }`"
                  />
                </div>
              </div>
            </div>
          </td>
          <td
            width="52px"
            class="notification-status"
          >
            <eewc-tooltip :text="$t(item.read ? 'Mark as new' : 'Mark as viewed')">
              <template #content="{ on, attrs }">
                <div
                  v-bind="attrs"
                  class="d-flex justify-center"
                  v-on="on"
                >
                  <eewc-button-common
                    type="clear"
                    :icon-size="20"
                    icon
                    text=""
                    class="notification-status__button"
                    :append-icon="`${!item.read ? '$icon_unread' : ' '}`"
                    v-bind="attrs"
                    :loading="processingIds.includes(item.id)"
                    @click.stop="markRead([item], !item.read)"
                    v-on="on"
                  />
                </div>
              </template>
            </eewc-tooltip>
          </td>
        </template>
        <template #skeletonRow>
          <td width="250px">
            <eewc-skeleton
              type="text"
              max-width="250px"
            />
          </td>
          <td width="100px">
            <eewc-skeleton
              type="text"
              max-width="100px"
            />
          </td>
          <td width="20px">
            <div class="d-flex justify-end pr-6">
              <eewc-skeleton type="icon" />
            </div>
          </td>
        </template>
      </eewc-table>
      <eewc-empty-state
        v-else
        class="notification--empty"
        :class="!unreadState ? 'notification--empty-unread' : ''"
        :icon-width="47"
        :icon-height="47"
        :width="240"
        icon="$icon_empty_notifications"
        :title="t(unreadState ? 'Your notifications page is empty' : 'Your notifications live here')"
      />
    </v-menu>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { t } from '@/plugins/i18n.ts';
import { timeSince, getNotificationIcon } from '@/service/helpers';
import router from '@/service/router';
import { useNotificationsStore } from '@/stores';
import { Notification } from '@eencloud/eewc-components/src/service/api-types';

const notificationsStore = useNotificationsStore();
const sidebarNotifications = ref<string[]>([]);
const processingIds = ref<string[]>([]);
const customShow = ref<boolean | null>(null);
const newNotification = ref<boolean>(false);
const unreadState = ref<boolean>(false);
const notificationList = ref<Notification[]>(notificationsStore.notifications);

const headerAction = computed(() => {
  const unreadCount = notificationList.value.filter((item) => item.read === false).length;
  return { unreadCount };
});
const tableNotifications = computed(() =>
  notificationList.value.map((notification) => ({
    ...notification,
  }))
);

watch(
  () => notificationsStore.recentNotifications,
  () => {
    showUnread(unreadState.value);
  }
);

watch(
  () => notificationsStore.newNotification,
  () => {
    newNotification.value = notificationsStore.newNotification;
  }
);

function dropDownMenuAction(action: 'bulkAction' | 'markRead' | 'markUnread', item: Notification) {
  switch (action) {
    case 'bulkAction':
      headerAction.value.unreadCount ? markRead(notificationList.value, true) : markRead(notificationList.value, false);
      break;
    case 'markRead':
      markRead([item], true);
      break;
    case 'markUnread':
      markRead([item], false);
      break;
  }
}

function calculateAge(val: string) {
  return timeSince(new Date(val));
}

async function markRead(items: Notification[], newStatus: boolean, shouldShowNotificationDialog = true) {
  let notificationId: string | null = null;
  items.forEach(async (item) => {
    processingIds.value = processingIds.value.concat(item.id);
    notificationId = await notificationsStore.updateRecentNotification(
      item.id,
      { read: newStatus },
      shouldShowNotificationDialog
    );
    processingIds.value = processingIds.value.filter((id) => id !== notificationId);
  });
}

function gotoNotifications() {
  if (router.currentRoute.path.indexOf('/notifications') === -1) {
    router.push({ name: 'Notifications history' });
  }

  customShow.value = false;
}

function showUnread(checked: boolean) {
  if (checked) {
    notificationList.value = notificationsStore.recentNotifications.filter(
      (notification) => notification.read === false
    );
  } else {
    notificationList.value = notificationsStore.recentNotifications;
  }
}

function openDialog() {
  customShow.value = true;
  if (notificationsStore.newNotification) {
    notificationsStore.updateNewNotificationState();
  }
}

function openHistoryBrowser(item: Notification) {
  markRead([item], true, false);
  if (['video', 'health'].includes(item.category)) {
    router.push({
      name: 'History Browser',
      query: {
        time: encodeURIComponent(item.timestamp),
        ids: item.actorId,
        playOnLoad: true,
      },
    });
    customShow.value = false;
  }
}
</script>

<style lang="scss">
@import '../../styles/public/main.scss';

.notification-dialog {
  &__btn {
    background: $secondaryLight !important;
    border-radius: 4px;
  }

  &__content {
    border: 1px solid $elements !important;
    border-radius: 4px !important;
    box-shadow: 0px 2px 12px 0px rgba(42, 52, 64, 0.08) !important;
    padding: 0 !important;
    margin-top: 4px !important;

    &--show-unread {
      @include body-3;
      color: $primaryLight;
    }

    &--switch {
      margin: 0 8px;
      input {
        display: none;
      }
    }

    &--divider {
      border-color: $elements !important;
    }

    &--sub-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 8px 0 20px;
      height: 40px;

      &-title {
        @include body-3;
        color: $primaryLight;
      }
    }

    &--table {
      max-height: 320px;
      overflow: overlay;
      margin: 4px;
      position: relative;
      overflow-x: hidden;

      .eewc-table {
        border: none;

        tr {
          height: 64px;
          &:nth-of-type(odd) {
            background-color: $backgrounds;
          }
          &:nth-of-type(even) {
            background-color: $primaryWhite;
          }
          td {
            &:first-child {
              border-radius: 4px 0 0 4px;
            }
            &:last-child {
              border-radius: 0 4px 4px 0;
            }
          }

          &:hover {
            .notification-status__button {
              border: 1px solid $elements !important;
            }
          }
        }

        thead {
          display: none;
        }

        .notification {
          width: 316px;
          padding: 10px 0 10px 16px !important;
          overflow: hidden;

          &--icon {
            left: 12px;
          }

          &__primary-text {
            &-read {
              @include body-2;
            }

            &-unread {
              @include subtitle-2;
            }
          }

          &__secondary-text {
            display: flex;
            &-read {
              @include body-3;
            }

            &-unread {
              @include subtitle-3;
            }

            &--age-read {
              color: $secondary;
              @include body-3;
            }

            &--age-unread {
              color: $secondary;
              @include subtitle-3;
            }
          }
        }

        .notification-status {
          padding-left: 8px !important;
          &__button {
            width: 32px !important;
            height: 32px !important;
            &:hover {
              border: 1px solid $elements !important;
              background: $backgrounds !important;
              box-shadow: none;
            }

            &:active {
              border: 1px solid $elements !important;
              background: $negativeClear !important;
            }
          }
        }
      }

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $secondaryMedium;
        border-radius: 8px;
        background-clip: content-box;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: $secondaryMedium !important;
      }
    }
    &--action-menu {
      overflow: hidden;
    }
  }
}

.notification--empty {
  height: 328px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 !important;
  width: 240px;

  &-unread {
    height: 368px;
  }
  .empty-state__title {
    @include subtitle-1;
    text-align: center;
  }

  .empty-state__text {
    @include body-2;
    text-align: center;
  }
}
</style>
