import api from '@eencloud/eewc-components/src/service/api';
import {
  AnntType,
  CameraRecordingStatus,
  CameraStatus,
  FLASH_DURATION,
} from '@eencloud/eewc-components/src/service/api-types';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import _ from 'lodash';

export const useCameraStatusStore = defineStore(
  'cameraStatus',

  function () {
    const cameraStatuses = ref<CameraStatus[]>([]);
    const initialAnalyticVal = {
      analytics: {
        lineCrossing: {
          in: 0,
          out: 0,
          delta: 0,
          counting: {
            enable: false,
            active: false,
            isInitialized: false,
            eventIds: [],
          },
          crossing: {
            enable: false,
            active: false,
            eventIds: [],
          },
        },
        intrusion: {
          enable: false,
          active: false,
        },
        loitering: {
          enable: false,
          active: false,
        },
        tampering: {
          enable: false,
          active: false,
        },
      },
    };

    function findCameraStatus(deviceId: string): CameraStatus {
      let cameraIndex = cameraStatuses.value.findIndex((cam) => cam.id === deviceId);
      if (cameraIndex === -1) {
        cameraStatuses.value = cameraStatuses.value.concat([{ id: deviceId }]);
        cameraIndex = cameraStatuses.value.length - 1;
      }

      const cameraStatus = cameraStatuses.value[cameraIndex];
      if (!cameraStatus.analytics) {
        cameraStatus.analytics = _.cloneDeep(initialAnalyticVal.analytics);
      }

      return cameraStatus;
    }

    function updateRecordingStatus(deviceId: string, recordingStatus: CameraRecordingStatus) {
      const cameraIndex = cameraStatuses.value.findIndex((cam) => cam.id === deviceId);
      if (cameraIndex !== -1) {
        const cam = cameraStatuses.value[cameraIndex];
        if (!cam.status) {
          cam.status = {
            recordingStatus,
          };
        } else {
          cam.status.recordingStatus = recordingStatus;
        }
      } else {
        const cameraStatus = {
          id: deviceId,
          status: {
            recordingStatus,
          },
        };
        cameraStatuses.value = cameraStatuses.value.concat([cameraStatus]);
      }
    }

    function updateCrossingAnnCount(deviceId: string, type: 'in' | 'out' | undefined, ananame: string) {
      const cameraStatus = findCameraStatus(deviceId);
      if (!cameraStatus.analytics) {
        return;
      }

      if (type && cameraStatus.analytics.lineCrossing.counting.eventIds.indexOf(ananame) !== -1) {
        ++cameraStatus.analytics.lineCrossing[type];
        cameraStatus.analytics.lineCrossing.delta =
          cameraStatus.analytics.lineCrossing.in - cameraStatus.analytics.lineCrossing.out;
        if (type === 'in') {
          cameraStatus.analytics.lineCrossing.counting.active = true;
          setTimeout(
            () => cameraStatus.analytics && (cameraStatus.analytics.lineCrossing.counting.active = false),
            FLASH_DURATION
          );
        }
      }

      if (cameraStatus.analytics.lineCrossing.crossing.eventIds.indexOf(ananame) !== -1) {
        cameraStatus.analytics.lineCrossing.crossing.active = true;

        setTimeout(
          () => cameraStatus.analytics && (cameraStatus.analytics.lineCrossing.crossing.active = false),
          FLASH_DURATION
        );
      }
    }

    function changeLineCrossAnntStatus(
      deviceId: string,
      type: 'counting' | 'crossing',
      subType: 'enable' | 'active',
      status: boolean,
      eventIds?: string[]
    ) {
      const cameraStatus = findCameraStatus(deviceId);
      if (!cameraStatus.analytics) {
        return;
      }
      cameraStatus.analytics.lineCrossing[type][subType] = status;

      if (eventIds) {
        cameraStatus.analytics.lineCrossing[type].eventIds = eventIds;
      }
    }

    function changeAnntStatus(deviceId: string, anntType: AnntType, type: 'enable' | 'active', status: boolean) {
      const cameraStatus = findCameraStatus(deviceId);
      if (!cameraStatus.analytics) {
        return;
      }

      switch (anntType) {
        case AnntType.ANNT_INTRUSION_DETECT_NS:
          cameraStatus.analytics.intrusion[type] = status;
          break;
        case AnntType.ANNT_LOITERING_NS:
          cameraStatus.analytics.loitering[type] = status;
          break;
        case AnntType.ANNT_TAMPERING_NS:
          cameraStatus.analytics.tampering[type] = status;
          break;
      }
    }

    function formattedDateTime(date: Date) {
      return date.toISOString().replaceAll('-', '').replaceAll(':', '').replaceAll('T', '').replaceAll('Z', '');
    }

    async function getAnalyticsSnapshot(deviceId: string, zone: string) {
      const cameraStatus = findCameraStatus(deviceId);
      if (cameraStatus.analytics?.lineCrossing.counting.isInitialized) {
        return;
      }
      const eenCameraDate = new Date().toEENCameraDateTimeStamp({ timezone: zone });
      const midnightToday = new Date(eenCameraDate);

      if (midnightToday.getHours() < 2) {
        midnightToday.setDate(midnightToday.getDate() - 1);
      }

      midnightToday.setHours(2, 0, 0, 0);
      const endDate = formattedDateTime(new Date());
      const startDate = formattedDateTime(midnightToday);

      const params = {
        namespace: AnntType.ANNT_LINE_CROSS_NS,
        in_name: 'in',
        out_name: 'out',
        device_id: deviceId,
        start_timestamp: startDate,
        end_timestamp: endDate,
      };

      try {
        const data = await api.getAnalyticSnapshot(params);
        if (!data || !Array.isArray(data)) {
          return;
        }
        data.shift(); //removed first data in array as it is just schema information

        data.forEach((item) => {
          const ananame = item[1][3];
          if (cameraStatus.analytics?.lineCrossing.counting.eventIds.includes(ananame)) {
            cameraStatus.analytics.lineCrossing.in = item[1][1];
            cameraStatus.analytics.lineCrossing.out = item[1][2];
            cameraStatus.analytics.lineCrossing.delta = item[1][4];
          }
        });
        cameraStatus.analytics && (cameraStatus.analytics.lineCrossing.counting.isInitialized = true);
      } catch (error) {
        console.log(`Error occured while fetching analytic snapshot data for camera ${deviceId}`);
      }
    }

    return {
      cameraStatuses,
      updateRecordingStatus,
      changeAnntStatus,
      updateCrossingAnnCount,
      changeLineCrossAnntStatus,
      findCameraStatus,
      getAnalyticsSnapshot,
    };
  }
);
