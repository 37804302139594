import { computed, onMounted, watch } from 'vue';
import useCookie from '@eencloud/eewc-components/src/service/useCookie.ts';
import { useUsersStore, useAuthStateStore } from '@/stores';

export function useAppcues() {
  const usersStore = useUsersStore();
  const authStateStore = useAuthStateStore();
  const { getCookie } = useCookie();

  const userContext = computed(() => usersStore.userContext);

  function configureAppcues() {
    if (!window.Appcues) return;

    const combinedUserData = {
      ...userContext.value,
      browser: navigator.userAgent,
      os: window.navigator.userAgentData?.platform,
      product: 'VMS',
      application: 'WebApp',
      platform: 'web',
      appVersion: config.version,
      language: getCookie('lang'),
      environment: config.IS_PRODUCTION ? 'production' : 'development',
    };

    window.Appcues.identify(combinedUserData.userId, combinedUserData);
  }

  async function initializeAppcues() {
    await usersStore.getUserContext();
    configureAppcues();
  }

  watch(
    () => authStateStore.loggedIn,
    (newValue, oldValue) => {
      if (newValue !== oldValue && newValue) {
        initializeAppcues();
      }
    },
    { immediate: true }
  );
}
