import { computed, reactive, Ref } from 'vue';
import { t } from '@/plugins/i18n';

import router from '@/service/router';
import { useAppStateStore } from '@/stores';

function usePOSNavigation(POSActiveRoute: Ref<string>) {
  const appState = useAppStateStore();

  const showHiddenFeaturesRef = computed(() => appState.showHiddenFeatures);

  const POS_SUB_ROUTES = ['POS Alerts', 'POS Transactions', 'POS Rules'];

  function getPOSSubmenuItems() {
    return [
      {
        id: 'transactions',
        name: t('Transactions'),
        active: POSActiveRoute.value === 'POS Transactions',
      },
    ];
  }
  const POSNavigationData = reactive({
    items: computed(() => {
      return {
        icon: '$icon_pos',
        name: t('POS'),
        active: POS_SUB_ROUTES.includes(POSActiveRoute.value),
        subItems: getPOSSubmenuItems(),
        route: 'POS Transactions',
      };
    }),
  });

  const handlePOSChildrenRoutes = (id: string) => {
    let targetRoute = '';

    switch (id) {
      case 'transactions': {
        targetRoute = '/pos/transactions';
        break;
      }
      case 'alerts': {
        targetRoute = '/pos/alerts';
        break;
      }
      case 'rules': {
        targetRoute = '/pos/rules';
        break;
      }
      default: {
        return false;
      }
    }

    if (router.currentRoute.path !== targetRoute) {
      router.push(targetRoute);
    }

    return true;
  };

  return { POSNavigationData, handlePOSChildrenRoutes, POS_SUB_ROUTES };
}

export default usePOSNavigation;
