import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { downloadTranslations } from '../service/localization/OTAClient';
import { createI18n, castToVueI18n } from 'vue-i18n-bridge';

Vue.use(VueI18n);
Vue.use(VueI18n, { bridge: true });

setLanguage(window.$cookies.get('lang'));

declare global {
  interface Window {
    $cookies: any;
    config: any;
  }
}

async function getLocalTranslations() {
  const messages: VueI18n.LocaleMessages = {};
  const codes = [
    'en-US',
    'nl-NL',
    'de-DE',
    'es-ES',
    'pt-PT',
    'pl-PL',
    'tr-TR',
    'ja-JP',
    'fr-FR',
    'sv-SE',
    'pt-BR',
    'it-IT',
  ];

  for (let i = 0, len = codes.length; i < len; i++) {
    const code = codes[i];
    const translations = await import(`../assets/locale/vms_${code}.json`);
    messages[code] = translations;
  }

  return messages;
}

const i18n = castToVueI18n(
  createI18n(
    {
      legacy: false,
      locale: 'en-US',
      fallbackLocale: 'en-US',
      silentTranslationWarn: true,
      messages: getLocalTranslations() as unknown as VueI18n.LocaleMessages, // the messages will be loaded from the local json files before updating them with the latest distribution from crowdin,
      missingWarn: false,
    },
    VueI18n
  )
);

async function setLanguage(eenLocale: string) {
  let langCode = eenLocale && eenLocale !== 'null' ? eenLocale : 'en-US';
  langCode = langCode.slice(0, 3) + langCode.slice(-2).toUpperCase();
  try {
    // set cookie lifetime to 10 seconds for the Crowdin pseudo language, because we don't want to persist it. It's only needed once after authorizing to Crowdin.
    window.$cookies.set('lang', eenLocale, eenLocale === 'aa-ER' ? '10s' : '1y');
    // use the latest translations from crowdin, expect on the development environment
    const request = import.meta.env.PROD && eenLocale !== 'en-US' ? await downloadTranslations(langCode) : null;
    const messages = request ? request : (await import(`../assets/locale/vms_${langCode}.json`)).default;

    i18n.setLocaleMessage(langCode, messages);
    i18n.locale = langCode;
  } catch (error) {
    console.log(error);
  }
}

function getCurrentLanguage() {
  return i18n.locale;
}

/**
 * Translates the given text using the current locale.
 *
 * @param text The text to translate.
 * @param values Optional values to replace in the translated text.
 * @returns The translated text as a string.
 * e.g: t("Hello {name}", { name: "John" })
 */
export function t(text: VueI18n.Path, values?: any) {
  return i18n.t(text, values) as string;
}

/**
 * Translates the given text using the current locale and pluralization rules.
 *
 * @param text The text to translate.
 * @param choice Optional choice index for pluralization.
 * @param values Optional values to replace in the translated text.
 * @returns The translated text as a string.
 * e.g: tc("apple", 5)
 * e.g: tc("camera", 1, { cameraName: "CM-008" })
 * See library documentation to understand usage of this function.
 * https://kazupon.github.io/vue-i18n/guide/pluralization.html
 */
export function tc(text: VueI18n.Path, choice?: VueI18n.Choice, values?: any) {
  return i18n.tc(text, choice!, values) as string;
}

Vue.prototype.$locale = {
  setLanguage,
  getCurrentLanguage,
};

export default {
  i18n,
  setLanguage,
  getCurrentLanguage,
};
