<template>
  <div>
    <eewc-page-title
      no-margin
      :title="t('Login')"
    />
    <v-form
      ref="loginForm"
      autocomplete="off"
    >
      <eewc-form-field
        :loading="isLoading"
        :label="t('Username')"
      >
        <eewc-text-field
          :value="username"
          autocomplete="off"
          :label="t('Username')"
          :rules="[rules.isValidPasswordLength({ min: MIN_LENGTH, max: MAX_LENGTH }), rules.isInvalidCharacter]"
          @input="updateUsername"
        />
      </eewc-form-field>
      <eewc-form-field
        :loading="isLoading"
        :label="t('Password')"
      >
        <eewc-text-field
          :value="password"
          data-testid="camera-settings-general-camera-username"
          autocomplete="new-password"
          :label="t('Password')"
          :type="showPassword ? 'text' : 'password'"
          :right-icon="showPassword ? '$icon_no_permissions' : '$icon_eye'"
          :rules="[rules.isValidPasswordLength({ min: MIN_LENGTH, max: MAX_LENGTH }), rules.isInvalidCharacter]"
          @input="updatePassword"
          @clickAppend="togglePasswordVisibility"
        />
      </eewc-form-field>
    </v-form>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue';

import { t } from '@/plugins/i18n.ts';
import { rules } from '@/service/validators';

type Credentials = {
  username?: string;
  password?: string;
};

const props = defineProps<{
  loading?: boolean;
  credentials?: Credentials;
}>();

const MIN_LENGTH = 1;
const MAX_LENGTH = 126;

const isInitializing = ref(false);
const loginForm = ref<HTMLFormElement | null>(null);
const showPassword = ref(false);
const username = ref<string>();
const password = ref<string>();

const isLoading = computed(() => props.loading || isInitializing.value);

onMounted(() => initialize());

watch(
  () => props.credentials,
  (oldValue, newValue) => {
    if (newValue !== oldValue) initialize();
  }
);

function initialize() {
  isInitializing.value = true;
  const { credentials } = props;

  if (!credentials) return;

  username.value = credentials.username;
  password.value = credentials.password;
  isInitializing.value = false;
}

function updateUsername(value: string) {
  username.value = value;
}

function updatePassword(value: string) {
  password.value = value;
}

function togglePasswordVisibility() {
  showPassword.value = !showPassword.value;
}

function isDirty() {
  if (!props.credentials) return false;

  const isUsernameDirty = username.value !== props.credentials.username;
  const isPasswordDirty = password.value !== props.credentials.password;

  return isUsernameDirty || isPasswordDirty;
}

function isValid() {
  if (!username.value && !password.value) return true; //  Return true if both username and password are empty (both are optional)
  if (username.value && password.value) return loginForm.value?.validate();

  return false;
}

function getUpdates() {
  const credentials: Credentials = {
    username: username.value,
    password: password.value,
  };

  return credentials;
}

defineExpose({ isDirty, isValid, getUpdates });
</script>
