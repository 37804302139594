import { ref, reactive } from 'vue';
import ApiList from '@eencloud/eewc-components/src/service/api';

export function WebSocketClient(
  accountId: string,
  requestPollingData: string,
  messageCallback: (event: any) => void,
  onCloseCallback: null | (() => void)
) {
  const disconnected = ref<boolean>(false);
  const ws = ref<WebSocket | null>(null);
  const DEFAULT_HOST = 'wss://c000.eagleeyenetworks.com/api/v2';

  const state = reactive({
    accountId,
    requestPollingData,
  });

  function connect(plainAuthToken: string) {
    disconnected.value = false;
    if (ws.value) {
      ws.value.close();
      ws.value = null;
    }

    // for e.g. converting https://api.c013.eagleeyenetworks.com/api/v3.0/ to wss://c013.eagleeyenetworks.com/api/v2/
    const wsUrl = ApiList.baseURL.replace('http', 'ws').replace('/v3.0', '/v2').replace('/api.', '/');
    const url = (wsUrl ?? DEFAULT_HOST) + '/Device/' + state.accountId + '/Events';
    ws.value = new WebSocket(url + '?A=' + plainAuthToken);

    ws.value.onerror = function (error) {
      console.log('websocket error - ', error);
    };

    ws.value.onopen = function () {
      console.log('websocket onopen');
      sendPollingData();
    };

    ws.value.onmessage = function (event) {
      messageCallback(event);
    };

    ws.value.onclose = function () {
      console.log('websocket onclose');
      disconnected.value = true;
      ws.value = null;
      onCloseCallback && onCloseCallback();
    };
  }

  function sendPollingData() {
    ws.value && state.requestPollingData && ws.value.send(state.requestPollingData);
  }

  function disconnect() {
    if (disconnected.value) {
      return;
    }
    ws.value && ws.value.close();
    ws.value = null;
  }
  return { connect, disconnect, sendPollingData, ws };
}
