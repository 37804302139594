export function getBrandedBaseUrlFromOrigin(): string {
  let baseUrl = window.location.origin;
  if (
    // for branded VMS
    baseUrl.includes('https://') &&
    baseUrl.includes('webapp.') &&
    !baseUrl.includes('https://webapp.')
  ) {
    const brand = baseUrl.split('https://')[1].split('.')[0];
    baseUrl = config.authServer.replace('://', `://${brand}.`);
  } else {
    baseUrl = config.authServer;
  }
  return baseUrl;
}

export function authRedirectUri(origin = window.location.origin): string {
  if (origin.includes('https://')) {
    return origin + window.location.pathname;
  } else {
    // for development environments
    return config.redirectUri;
  }
}
