import { ref } from 'vue';
/*
 * This composable is used to determine if any of the registered async operations is still in progress.
 * This is can be useful for showing a loading spinner to indicate that some async operations are still in progress.
 */
export default function useConcurrentApiTracker() {
  const concurrentEventCalls: string[] = [];
  const eventsLoading = ref(false);

  function registerApiCall(apiCallId: string) {
    concurrentEventCalls.push(apiCallId);
    eventsLoading.value = true;
  }

  function unregisterApiCall(apiCallId: string) {
    concurrentEventCalls.splice(concurrentEventCalls.indexOf(apiCallId), 1);
    eventsLoading.value = concurrentEventCalls.length > 0;
  }

  function resetConcurrentApiTracker() {
    concurrentEventCalls.length = 0;
    eventsLoading.value = false;
  }

  return { registerApiCall, unregisterApiCall, eventsLoading, resetConcurrentApiTracker };
}
