import VueRouter from 'vue-router';
import { t } from '@/plugins/i18n.ts';
import { authRedirectUri } from '@/service/branding';
import api from '@eencloud/eewc-components/src/service/api';
import sensorsRoutes from '@/pages/Sensors/navigation/sensorsRoutes.ts';
import useHistoryBrowserRouterGuard from './historyBrowserGuard.js';
import POSRoutes from '@/pages/POS/POSRoutes.ts';
import VSPRoutes from '@/pages/VSP/navigation/VSPRoutes.ts';
import AlertManagerRoutes from '@/pages/AlertManager/navigation/AlertManagerRoutes.ts';
import AdminRoutes from '@/pages/Users/AdminRoutes.ts';

// Added lazy loading of routes to improve performance https://router.vuejs.org/guide/advanced/lazy-loading.html
const Dashboard = () => import('@/pages/Dashboard/Dashboard.vue');
const LayoutsRouter = () => import('@/pages/Layouts/LayoutsRouter.vue');
const Tags = () => import('@/pages/Tags/Tags.vue');
const FirstResponder = () => import('@/pages/FirstResponder/ResponderCameraLayout.vue');
const Archive = () => import('@/pages/Archive/Archive.vue');
const Sites = () => import('@/pages/Sites/Sites.vue');
const Notifications = () => import('@/pages/Notifications/Notifications.vue');
const Live = () => import('@/pages/Live/Live.vue');
const Map = () => import('@/pages/Map/Map.vue');
const Site = () => import('@/pages/Sites/Site.vue');
const HistoryBrowser = () => import('@/pages/HistoryBrowser/HistoryBrowser.vue');
const MyProfile = () => import('@/pages/MyProfile/MyProfile.vue');
const IframeError = () => import('@/pages/IFrame/IframeError.vue');
const Download = () => import('@/pages/Archive/Download.vue');
const Reports = () => import('@/pages/Archive/Reports.vue');
const VideoSearch = () => import('@/pages/VideoSearch/VideoSearch.vue');
const FaceSearch = () => import('@/pages/VideoSearch/FaceSearch.vue');
const HelpCenter = () => import('@/pages/HelpCenter/HelpCenter.vue');
const FloorPlans = () => import('@/pages/Sites/FloorPlans.vue');

import { useAuthStateStore, useAppStateStore } from '@/stores';
import {
  getToken,
  authKey,
  plainAuthKey,
  getJwtFromExchangeToken,
  getBaseUrl,
} from '@eencloud/eewc-components/src/service/auth.js';
import ApiList from '@eencloud/eewc-components/src/service/api';
import useTopBarBreadcrumbs from '../useTopBarBreadcrumbs.ts';
import routePermissionCheck from '../routePermissionCheck.ts';
const SpeakerSettings = () => import('@/pages/Speakers/SpeakerSettings.vue');
const AddCameraConfigurable = () => import('@/pages/AddDevices/AddCameraConfigurable.vue');
const AddBridge = () => import('@/pages/Bridges/AddBridge.vue');
import useNoSavedChangesRouterGuard from '@/service/router/noSavedChangesGuard';
const AvailableDevices = () => import('@/pages/Dashboard/AvailableDevices.vue');
const Settings = () => import('@/pages/Bridges/Settings.vue');
const AddCameraDirectPage = () => import('@/pages/AddDevices/AddCameraDirectPage.vue');
const SwitchSettings = () => import('@/pages/Switches/SwitchSettings.vue');
const AddRtspCamera = () => import('@/pages/AddDevices/AddRtspCamera.vue');
import useIframeAuth from '@/service/useIframeAuth';
import MultiCameraSettings from '@/pages/Cameras/MultiCameraSettings.vue';
import EventMetrics from '@/pages/Metrics/camera/EventChart.vue';
const CameraSettings = () => import('@/pages/Cameras/CameraSettings.vue');
const AddSpeaker = () => import('@/pages/Speakers/AddSpeaker.vue');

/**
 * filterOption: is used to show the filter option in topbar when the corresponding route page is rendered
 */

const routes = [
  {
    path: '*',
    redirect: (to) => {
      if (window.self !== window.top) {
        return '/iframeError';
      } else return '/layouts';
    },
    name: '/',
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    props: true,
    meta: {
      auth: true,
      analyticsName: '/dashboard',
      filterOption: true,
      rootRoute: true,
    },
  },
  {
    path: '/bridges/add',
    name: 'Add bridge',
    component: AddBridge,
    props: true,
    meta: {
      auth: true,
    },
  },
  {
    path: '/bridges/:id',
    name: 'Bridge settings',
    component: Settings,
    props: true,
    meta: {
      auth: true,
    },
  },
  {
    path: '/layouts/:id?',
    name: 'Layouts',
    component: LayoutsRouter,
    props: true,
    meta: {
      auth: true,
      analyticsName: '/layouts',
      filterOption: true,
      rootRoute: true,
    },
  },
  {
    path: '/responder/:id?',
    name: 'Responder',
    component: FirstResponder,
    meta: {
      auth: true,
      analyticsName: '/responder',
      filterOption: true,
      rootRoute: true,
    },
  },
  {
    path: '/tags/:id?',
    name: 'Tags',
    component: Tags,
    meta: {
      auth: true,
      analyticsName: '/tags',
      filterOption: true,
      rootRoute: true,
    },
  },
  {
    path: '/map',
    name: 'Map',
    component: Map,
    meta: {
      auth: true,
      analyticsName: '/map',
      filterOption: true,
      rootRoute: true,
    },
  },
  {
    path: '/archive/files/:id?',
    name: 'Archive',
    component: Archive,
    meta: {
      auth: true,
      filterOption: true,
      permissions: ['viewArchive'],
      rootRoute: true,
    },
  },
  {
    path: '/archive/download/:id?',
    name: 'Download',
    component: Download,
    meta: {
      auth: true,
      filterOption: true,
      rootRoute: true,
    },
  },
  {
    path: '/archive/reports',
    name: 'Reports',
    component: Reports,
    meta: {
      auth: true,
      filterOption: true,
      permissions: ['viewArchive'],
      rootRoute: true,
    },
  },
  {
    path: '/sites',
    name: 'Sites',
    component: Sites,
    meta: {
      auth: true,
      analyticsName: '/sites',
      filterOption: true,
      rootRoute: true,
    },
  },
  {
    path: '/locations/floorplans',
    name: 'Floor plans',
    component: FloorPlans,
    meta: {
      auth: true,
      filterOption: false,
      rootRoute: true,
    },
  },
  {
    path: '/sites/:id',
    name: 'Site',
    component: Site,
    meta: {
      auth: true,
    },
  },
  {
    path: '/video/:id',
    alias: '/live/:id',
    name: 'Video',
    component: Live,
    meta: {
      auth: true,
      permissions: ['viewLiveVideo'],
      tooltipText: t('Live view'),
    },
  },
  {
    path: '/videoSearch/face/:id?',
    name: 'FaceSearch',
    component: FaceSearch,
    meta: {
      auth: true,
    },
  },
  {
    path: '/videoSearch',
    name: 'VideoSearch',
    component: VideoSearch,
    meta: {
      auth: true,
      filterOption: true,
      rootRoute: true,
    },
  },
  {
    path: '/notifications',
    name: 'Notifications history',
    component: Notifications,
    meta: {
      auth: true,
      tooltipText: t('Notifications history'),
    },
  },
  {
    path: '/history',
    name: 'History Browser',
    component: HistoryBrowser,
    meta: {
      auth: true,
      permissions: ['viewHistoricVideo'],
      tooltipText: t('History browser'),
    },
  },
  {
    path: '/hb',
    name: 'External History Browser',
    component: HistoryBrowser,
    meta: {
      auth: true,
    },
  },
  {
    path: '/videoext/:id',
    name: 'External Live Video Player',
    component: Live,
    meta: {
      auth: true,
    },
  },
  {
    path: '/myprofile',
    name: 'My profile',
    component: MyProfile,
    meta: {
      auth: true,
    },
  },
  ...AdminRoutes,
  ...AlertManagerRoutes,
  ...VSPRoutes,
  ...POSRoutes,
  {
    path: '/hb+live/:id',
    alias: '/historylive/:id',
    name: 'hb+live',
    meta: {
      auth: true,
    },
    beforeEnter: (to, from, next) => {
      if (window.self !== window.top) {
        next({ replace: true, name: 'External History Browser', query: { ids: to.params.id } });
      } else {
        next({ replace: true, name: 'History Browser', query: { ids: to.params.id } });
      }
    },
  },
  {
    path: '/iframeError',
    name: 'IFrame Error',
    component: IframeError,
  },
  {
    path: '/speaker/:id',
    name: 'Speaker settings',
    component: SpeakerSettings,
    meta: {
      auth: true,
    },
  },
  {
    path: '/add-camera-configurable/:id',
    name: 'Add camera',
    component: AddCameraConfigurable,
    meta: {
      auth: true,
    },
  },
  {
    path: '/add-speaker/:id',
    name: 'Add speaker',
    component: AddSpeaker,
    meta: {
      auth: true,
    },
  },
  {
    path: '/cameras/:id',
    name: 'Camera settings',
    component: CameraSettings,
    meta: {
      auth: true,
    },
  },
  {
    path: '/cameras/analytics-graphs/:id',
    name: 'Analytics graphs',
    component: EventMetrics,
    meta: {
      auth: true,
      rootRoute: false,
    },
  },
  {
    path: '/multi-cameras/:id',
    name: 'Multi-camera settings',
    component: MultiCameraSettings,
    meta: {
      auth: true,
    },
  },
  {
    path: '/devices/available/:type',
    name: 'Available devices',
    component: AvailableDevices,
    meta: {
      auth: true,
    },
  },
  {
    path: '/add-camera-direct',
    name: 'Add camera direct',
    component: AddCameraDirectPage,
    meta: {
      auth: true,
    },
  },
  {
    path: '/add-rtsp-camera',
    name: 'Add RTSP camera',
    component: AddRtspCamera,
    meta: {
      auth: true,
    },
  },
  {
    path: '/switch/:id',
    name: 'Switch Settings',
    component: SwitchSettings,
    meta: {
      auth: true,
    },
  },
  {
    path: '/help-center',
    name: 'Help center',
    component: HelpCenter,
    meta: {
      auth: true,
    },
  },
  {
    path: '/help-center-external',
    name: 'External help center',
    component: HelpCenter,
    meta: {
      auth: true,
    },
  },
  ...sensorsRoutes,
];
const router = new VueRouter({ routes });
let lastNavigationMethod = null;

const originalPush = router.push;
const originalReplace = router.replace;
const originalGo = router.go;

router.push = function push(location, onResolve, onReject) {
  lastNavigationMethod = 'push';
  return originalPush.call(this, location, onResolve, onReject);
};

router.replace = function replace(location, onResolve, onReject) {
  lastNavigationMethod = 'replace';
  return originalReplace.call(this, location, onResolve, onReject);
};

router.go = function go(n) {
  lastNavigationMethod = 'go';
  return originalGo.call(this, n);
};

router.beforeEach(async (to, from, next) => {
  const authStateStore = useAuthStateStore();
  const { renewTokenViaIframeAncestor } = useIframeAuth();

  async function beforeEachCallback() {
    const appState = useAppStateStore();
    if (appState.isIFrame && !appState.iframeAuthenticated) {
      appState.iframeAuthenticated = true;
      renewTokenViaIframeAncestor({ name: to.name, query: to.query, params: to.params });
    } else if (to.query.exchangetoken) {
      const exchangeToken = to.query.exchangetoken;
      const response = await getJwtFromExchangeToken(exchangeToken);
      if (!response) {
        authStateStore.rerouteToOAuthLogin();
      } else {
        api.getMediaCookie();
        authStateStore.loginSuccessful();
      }
    } else if (to.query.code) {
      config.redirectUri = authRedirectUri();
      await getToken(to.query.code);
      authStateStore.loginSuccessful();
      api.getMediaCookie();
      const redirectRoute = localStorage.getItem('redirectRoute');
      localStorage.removeItem('redirectRoute');

      if (redirectRoute) {
        const result = routePermissionCheck(to);
        if (!result) {
          next('/');
        } else {
          next(JSON.parse(redirectRoute));
        }
      } else next('/');
    } else if (authKey() && getBaseUrl()) {
      if (!authStateStore.loggedIn) {
        ApiList.token = authKey();
        ApiList.baseURL = getBaseUrl();
        plainAuthKey() && (ApiList.plainAuthToken = plainAuthKey());
        authStateStore.loginSuccessful();
      }
      next();
    } else {
      localStorage.setItem('redirectRoute', JSON.stringify(to));
      if (authKey() && !getBaseUrl()) {
        // if jwt token is found and base URL is NOT found, print the error on console
        const baseUrlMissingError = new Error('base URL not found in cookies');
        console.error(baseUrlMissingError);
      }
      !appState.isIFrame && authStateStore.logout();
      return; // this is important, if it is routing to login page, below code should not be executed
    }

    if (to.name === 'External History Browser' || to.name === 'External Live Video Player') {
      const appState = useAppStateStore();
      appState.externalBrowser = true;
    }

    if (to.query?.fromMobileViewer) {
      appState.externalBrowser = true;
    }

    if (!appState.isIFrame) {
      const result = routePermissionCheck(to);
      if (!result) {
        next('/');
        return;
      }
    }

    if (authStateStore.loggedIn) {
      useHistoryBrowserRouterGuard(to, from);
      useTopBarBreadcrumbs(lastNavigationMethod, to, from);
    }
  }

  await useNoSavedChangesRouterGuard(beforeEachCallback);

  next();
});

router.afterEach((to) => {
  if (to) {
    const appState = useAppStateStore();
    appState.updateCurrentRoute(to);
  }
});

router.onError((error, to) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed')
  ) {
    if (!to?.fullPath) {
      window.location.reload();
    } else {
      window.location = to.fullPath;
    }
  }
});

export default router;
