import { ref, Ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';

import api from '@eencloud/eewc-components/src/service/api';
import { CameraSettingsQueryParams, CameraSettingsResponse } from '@eencloud/eewc-components/src/service/api-types';

import { QueryKeys } from '@/queries';

export const useGetCameraSettings = (
  cameraId: Ref<string>,
  params: Ref<CameraSettingsQueryParams>,
  enabled: Ref<boolean> = ref(true)
) => {
  return useQuery<CameraSettingsResponse | undefined, Error>({
    queryKey: [QueryKeys.GET_EVENT_METRICS, cameraId.value],
    queryFn: () => api.fetchCameraSettings(cameraId.value, params.value),
    enabled: enabled,
  });
};
