/**
 * This composable function sets up Server-Sent Events (SSE) subscriptions and event fetching
 * based on the current user's account ID. It integrates with Vue lifecycle hooks and state
 * management stores to manage the SSE lifecycle and fetch relevant events.
 */
import { onUnmounted, watch } from 'vue';
import { useAccountStore } from '../stores';
import { useSSEStore } from '@/stores/sse';

export default function useSSE() {
  // Access the accounts Store to retrieve loggedin user account information.
  const accountStore = useAccountStore();

  // Access the SSE Store to manage subscriptions and related actions.
  const sseStore = useSSEStore();

  /**
   * Watches for changes in the current user's account ID.
   * When a new account ID is detected, it resets the SSE subscription and fetches events.
   */
  watch(
    () => accountStore.account?.id,
    (newVal, oldVal) => {
      if (newVal !== oldVal && newVal) {
        sseStore.setSubscription();
      }
    },
    { immediate: true }
  );

  /**
   * Lifecycle hook that cleans up the SSE subscription when the component is unmounted.
   */
  onUnmounted(() => {
    sseStore.closeSubscription();
  });
}
