import { useMessagingStore, useUsersStore } from '@/stores';
import { Route } from 'vue-router';
import { t } from '@/plugins/i18n.ts';
import router from '@/service/router';

export default function routePermissionCheck(to: Route) {
  const currentRoute = to || router.currentRoute;
  const usersStore = useUsersStore();
  const messagingStore = useMessagingStore();

  let checkRoutePermission = true;
  if (currentRoute.meta?.permissions && usersStore.permissions) {
    checkRoutePermission = (currentRoute.meta?.permissions as unknown as string[]).some(
      (permission) => usersStore.permissions[permission]
    );
  }

  if (!checkRoutePermission) {
    showDialog(currentRoute.name ?? '');
  }
  return checkRoutePermission;

  function showDialog(routeName: string) {
    const body =
      routeName === 'History Browser' ? t('You are not authorized to view recorded videos on this camera.') : undefined;
    messagingStore.addNotification({
      iconColor: 'negative',
      icon: '$icon_attention',
      title: t('No permission'),
      body,
    });
  }
}
