import Highcharts, { Options, YAxisOptions } from 'highcharts';
import vuetify from '@/plugins/vuetify';
import { t } from '@/plugins/i18n.ts';
import seriesBaseConfig from '../config/seriesBaseConfig';
import { DataPoint } from '../types';

/**
 * The function calculates the job name
 * @param label the label for y-axis
 * @returns the YAxisOptions
 */

export function getYaxisConfig(label: string): YAxisOptions[] {
  return [
    {
      tickPosition: 'inside',
      minorTickPosition: 'inside',
      minPadding: 0,
      maxPadding: 0.2,
      showLastLabel: true,
      showFirstLabel: false,
      endOnTick: false,
      tickPixelInterval: 50,
      visible: false,
      opposite: true,
      labels: {
        format: `{value} ${label}`,
        style: {
          color: vuetify.framework.theme.themes.light.secondary,
        },
      },
    },
    {
      tickPosition: 'inside',
      minorTickPosition: 'inside',
      minPadding: 0,
      maxPadding: 0.2,
      showLastLabel: true,
      showFirstLabel: false,
      endOnTick: false,
      opposite: false,
      visible: true,
      linkedTo: 0,
      tickPixelInterval: 50,
      labels: {
        format: `{value} ${label}`,
        style: {
          color: vuetify.framework.theme.themes.light.secondary,
        },
      },
    },
  ];
}

export function getChartOptionsBaseConfig(label: string, title?: string, timezone?: string): Options {
  return {
    chart: {
      alignTicks: false,
      type: 'column',
      spacingTop: 10,
      spacingLeft: 5,
      spacingBottom: 5,
      spacingRight: 5,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    time: {
      timezone,
    },
    loading: {
      style: {
        position: 'absolute',
        backgroundColor: vuetify.framework.theme.themes.light.primaryWhite,
        opacity: 0.75,
        textAlign: 'center',
      },
      labelStyle: {
        fontWeight: 'bold',
        fontSize: '14px',
        position: 'relative',
        top: '75px',
      },
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: true,
      height: 60,
      maskFill: 'rgba(102, 179, 255, 0.3)',
      series: {
        type: 'areaspline',
        fillOpacity: 0.4,
      },
    },
    lang: {
      noData: 'No Data to display',
      viewFullscreen: t('View in fullscreen'),
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px',
        color: vuetify.framework.theme.themes.light.secondaryMedium,
      },
    },
    xAxis: {
      lineWidth: 4,
      type: 'datetime',
      startOnTick: false,
      endOnTick: false,
      minPadding: 0,
      maxPadding: 0,
      ordinal: false,
      dateTimeLabelFormats: {
        minute: '%H:%M',
        hour: '%H:%M',
        day: '%H:%M<br/>%b-%d',
        month: '%Y-%m',
        year: '%Y',
      },
      lineColor: vuetify.framework.theme.themes.light.secondaryLight,
      className: 'highcharts-xaxis',
      labels: {
        style: {
          color: vuetify.framework.theme.themes.light.secondary,
        },
        formatter: function () {
          return new Date(this.value as number)
            .toEENCameraDateTimeStamp({
              timezone,
              showMilliseconds: false,
              hour12: false,
              showDate: true,
              showTime: true,
            })
            .replace(/(\d{2}:\d{2}):\d{2}/, '$1') // Removing seconds
            .replace(/(\d{2}:\d{2}) (.*)/, '$1 $2'); // Adjust to "HH:mm MMM-DD";
        },
      },
      min: getPastDateUTC(),
      max: Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
    },
    yAxis: getYaxisConfig(label),
    tooltip: {
      valueDecimals: 2,
      padding: 8,
      valueSuffix: label,
      split: false,
      shared: true,
      borderRadius: 8,
      borderWidth: 1,
      backgroundColor: vuetify.framework.theme.themes.light.primaryWhite,
      borderColor: vuetify.framework.theme.themes.light.elements,
      shadow: false,
      style: {
        fontSize: '12px',
        color: vuetify.framework.theme.themes.light.secondary,
        fontfamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '14px',
      },
      xDateFormat: '%y-%y-%y %H:%M:%S',
      formatter: function () {
        return getFormattedTooltip.call(this, timezone);
      },
    },
    title: {
      text: title,
      align: 'center',
      x: -30,
      y: 100,
      style: {
        fontSize: '20px',
        fontFamily: 'Roboto',
        fontWeight: '500',
        lineHeight: '40px',
        color: vuetify.framework.theme.themes.light.primary,
      },
    },
  };
}

function getPastDateUTC() {
  const currentDate = new Date();

  const currentDateUTC = new Date(
    Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate())
  );

  // Subtract one day
  currentDateUTC.setUTCDate(currentDateUTC.getUTCDate() - 1);

  // Return the timestamp at midnight UTC of the previous day
  return currentDateUTC.getTime();
}

export function getMinAndMaxDate(dataPoints: [number, number][] | undefined, date: string | undefined) {
  let minValue: number;
  let maxValue: number;

  if (dataPoints && dataPoints.length > 0) {
    // Extract timestamps from data points
    const timestamps = dataPoints.map((point) => point[0]);
    // Determine the earliest and latest timestamps
    minValue = Math.min(...timestamps);
    maxValue = Math.max(...timestamps);
  } else {
    // If no data, default to offsetDate and current date
    const lastTimestamp = new Date();
    let firstTimestamp: Date;

    if (date) {
      firstTimestamp = new Date(date);
    } else {
      // Subtract 1 day from the current date
      firstTimestamp = new Date(lastTimestamp);
      firstTimestamp.setDate(firstTimestamp.getDate() - 1);
    }

    minValue = firstTimestamp.getTime();
    maxValue = lastTimestamp.getTime();
  }

  return { min: minValue, max: maxValue };
}

export function getSeriesConfig(name: string, data: DataPoint[] | undefined, groupingInterval: number) {
  const dataGroupingConfig = getDataGroupingConfig(groupingInterval);
  return Object.assign({}, seriesBaseConfig, {
    name,
    data,
    dataGrouping: dataGroupingConfig,
    tooltip: {
      valueDecimals: 0,
    },
  });
}

export function getDataGroupingConfig(intervalInHours: number) {
  return {
    enabled: true,
    approximation: 'sum',
    forced: true,
    units: [['hour', [intervalInHours]]],
  };
}

function getFormattedTooltip(this: Highcharts.TooltipFormatterContextObject, timezone?: string): string {
  const formattedDate = new Date(this.x as number).toEENCameraDateTimeStamp({
    timezone,
    showMilliseconds: false,
  });

  const formattedValue = Highcharts.numberFormat(this.y as number, 0, '.', ',');

  return `Date: ${formattedDate}<br/>events: ${formattedValue}`;
}

export function getFilteredNonZeroDataPoints(eventDataList: DataPoint[]) {
  return eventDataList?.some((dataPoint: number[]) => dataPoint[1] !== 0) ? eventDataList : [];
}
