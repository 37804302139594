<template>
  <div class="general-settings">
    <div class="general-settings__form">
      <v-form ref="generalSettingsForm">
        <eewc-form-field
          :loading="isLoading"
          :label="t('Name')"
        >
          <eewc-text-field
            data-testid="multi-camera-settings-general-camera-name"
            :value="name"
            autocomplete="off"
            :label="t('Name')"
            :rules="[rules.requiredText]"
            @input="updateName"
          />
        </eewc-form-field>
        <eewc-form-field
          :loading="isLoading || timezoneLoading"
          :label="t('Time zone')"
          class="general-settings__form__time-zone"
        >
          <eewc-auto-complete
            v-if="supportedTimeZones"
            data-testid="multi-camera-settings-general-camera-timezone"
            :value="timeZone"
            :items="supportedTimeZones"
            :label-text="t('Select time zone')"
            :rules="[rules.requiredText]"
            @input="updateTimeZone"
          />
        </eewc-form-field>
      </v-form>
      <eewc-form-field
        :loading="isLoading"
        :label="t('Tags')"
        :optional="t('(optional)')"
      >
        <eewc-chips-input
          data-testid="multi-camera-settings-general-camera-tags"
          :value="tags"
          :placeholder="t('Add a tag')"
          :label="t('Tags')"
          @input="updateTags"
        />
      </eewc-form-field>
      <eewc-form-field
        :loading="isLoading"
        :label="t('Notes')"
        :optional="t('(optional)')"
      >
        <eewc-text-area
          data-testid="multi-camera-settings-general-camera-notes"
          :value="notes"
          :label="t('Add notes')"
          @input="updateNotes"
        />
      </eewc-form-field>
      <eewc-separator />
      <login-credential
        ref="loginCredentialRef"
        :credentials="cameraSettingsData?.credentials"
        :loading="isLoading"
      />
    </div>
    <slot name="cameraInfoBar" />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';

import _ from 'lodash';

import { t } from '@/plugins/i18n.ts';

import {
  ApiMultiCameraUpdate,
  ApiMultiCameraWithIncludes,
  SettingsGet,
  TimeZone,
} from '@eencloud/eewc-components/src/service/api-types';

import { useUsersStore } from '@/stores';
import { rules } from '@/service/validators';

import LoginCredential from '@/pages/Cameras/LoginCredential.vue';

const props = defineProps<{
  cameraData?: ApiMultiCameraWithIncludes;
  cameraDataLoading: boolean;
  cameraSettingsData?: SettingsGet;
  cameraSettingsDataLoading: boolean;
}>();

const usersStore = useUsersStore();

const name = ref<string>();
const timezoneLoading = ref(false);
const supportedTimeZones = ref<string[]>();
const timeZone = ref<TimeZone>();
const tags = ref<string[]>();
const notes = ref<string>();
const generalSettingsForm = ref<HTMLFormElement | null>(null);
const loginCredentialRef = ref<HTMLFormElement | null>(null);

const isLoading = computed(() => props.cameraDataLoading || props.cameraSettingsDataLoading);

async function getSupportedTimeZones() {
  timezoneLoading.value = true;

  const data = await usersStore.getSupportedTimeZones();
  supportedTimeZones.value = data.map((timeZone) => timeZone.name).filter((name) => name !== undefined); // to filter out undefined values

  timezoneLoading.value = false;
}

function initializeGeneralSettingsData() {
  const { cameraData, cameraSettingsData } = props;

  name.value = cameraData?.name;
  timeZone.value = cameraSettingsData?.timeZone;
  tags.value = cameraData?.tags;
  notes.value = cameraData?.notes;
}

function updateName(value: string) {
  name.value = value;
}

function updateTimeZone(value: TimeZone) {
  timeZone.value = value;
}

function updateTags(value: string[]) {
  tags.value = value;
}

function updateNotes(value: string) {
  notes.value = value;
}

function isValid() {
  const isLoginCredentialValid = loginCredentialRef.value?.isValid() ?? true;
  const generalSettingsValid = generalSettingsForm.value?.validate() ?? true;

  return isLoginCredentialValid && generalSettingsValid;
}

function isCameraDataDirty() {
  const { cameraData } = props;
  const isNameDirty = name.value !== cameraData?.name;
  const isTagsDirty = tags.value !== cameraData?.tags;
  const isNotesDirty = notes.value !== cameraData?.notes;

  return isNameDirty || isTagsDirty || isNotesDirty;
}

function isTimeZoneDirty() {
  return timeZone.value !== props.cameraSettingsData?.timeZone;
}

function isLoginCredentialDirty() {
  return loginCredentialRef.value?.isDirty();
}

const isDirty = {
  cameraDataDirty: isCameraDataDirty,
  timeZoneDirty: isTimeZoneDirty,
  loginCredentialDirty: isLoginCredentialDirty,
};

function getCameraDataUpdates() {
  const cameraData: ApiMultiCameraUpdate = {
    name: name.value,
    tags: tags.value,
    notes: notes.value,
  };
  return cameraData;
}

function getTimeZoneUpdates() {
  return timeZone.value;
}

function getLoginCredentialUpdates() {
  return loginCredentialRef.value?.getUpdates();
}

const getUpdates = {
  cameraData: getCameraDataUpdates,
  timeZoneData: getTimeZoneUpdates,
  loginCredentialData: getLoginCredentialUpdates,
};

onMounted(() => {
  initializeGeneralSettingsData();
  getSupportedTimeZones();
});

watch(
  () => [props.cameraData, props.cameraSettingsData],
  () => {
    initializeGeneralSettingsData();
  }
);

defineExpose({
  isValid,
  isDirty,
  getUpdates,
});
</script>

<style lang="scss" scoped>
@import '@/styles/public/main.scss';

.general-settings {
  color: $primary;
  display: flex;
  justify-content: space-between;
  padding-top: 8px;

  &__form {
    width: 530px;

    &__time-zone {
      margin-bottom: 20px;
    }
  }

  &__turn-on-checkbox {
    margin-bottom: 8px !important;
  }
}
</style>
