import router from './router';

export function useHelpCenterRedirection() {
  const BASE_ROUTE = config.is_production
    ? 'https://helpcenter.eagleeyenetworks.com'
    : 'https://helpcenter.stage.eagleeyenetworks.com';
  const WINDOW_WIDTH = 400;
  const WINDOW_HEIGHT = 879;
  const leftPosition = window.screen.width - WINDOW_WIDTH; // Calculate left position for the window

  // Get the current route and id
  let route = window.location.hash;
  const id = router.currentRoute.params.id;
  const hasQuery = !!Object.keys(router.currentRoute.query).length;
  const language = window.$cookies.get('lang') || 'en-US';

  if (hasQuery) {
    route = route.split('?')[0];
  }

  const sanitizedRoute = route.replace(`/${id}`, '').replace('#', '');

  // Construct the final URL and open it in a new window
  const finalUrl = `${BASE_ROUTE}${sanitizedRoute}?lang=${language}`;
  const windowOptions = `width=${WINDOW_WIDTH}, height=${WINDOW_HEIGHT}, top=0, left=${leftPosition}`;

  window.open(finalUrl, 'helpCenterWindow', windowOptions);
}
