import { Ref, computed, reactive } from 'vue';
import router from '@/service/router';
import { t } from '@/plugins/i18n';
import { useAppStateStore } from '@/stores';

function useSensorsNavigation(sensorsActiveRoute: Ref<string>) {
  const appState = useAppStateStore();
  const showHiddenFeaturesRef = computed(() => appState.showHiddenFeatures);

  function routeActive(routeName: string) {
    return sensorsActiveRoute.value === routeName;
  }

  function sensorSubMenuItems() {
    return [];
  }

  const sensorsNavigationData = reactive({
    items: computed(() => {
      return showHiddenFeaturesRef.value
        ? {
            icon: '$icon_sensor',
            name: t('Sensors'),
            active: routeActive('Sensors') || routeActive('Measurements') || routeActive('Sensor measurements'),
            subItems: sensorSubMenuItems(),
            route: 'Measurements',
          }
        : null;
    }),
  });

  const handleSensorsChildrenRoutes = (id: string): boolean => {
    let targetRoute = '';

    switch (id) {
      case 'devices':
        targetRoute = '/sensors';
        break;
      case 'measurements':
        targetRoute = '/sensors/measurements';
        break;
      default:
        return false;
    }

    if (router.currentRoute.path !== targetRoute) {
      router.push(targetRoute);
    }

    return true;
  };

  return {
    sensorsNavigationData,
    handleSensorsChildrenRoutes,
  };
}

export default useSensorsNavigation;
