import { Ref, computed, reactive } from 'vue';
import router from '@/service/router';
import { t } from '@/plugins/i18n';
import { useAppStateStore } from '@/stores';

function useAlertManagerNavigation(AlertManagerActiveRoute: Ref<string>) {
  const appState = useAppStateStore();
  const showHiddenFeaturesRef = computed(() => appState.showHiddenFeatures);

  const ALERT_MANAGER_SUB_ROUTES = [
    'Alert manager alerts',
    'Alert manager rules',
    'Alert actions',
    'Add rule',
    'Edit rule',
    'Add action',
    'Edit action',
  ];

  function getAlertManagerSubmenuItems() {
    const isActiveRulesRoute = ['Alert manager rules', 'Add rule', 'Edit rule'].includes(AlertManagerActiveRoute.value);

    let items = [
      {
        id: 'alerts',
        name: t('Alerts'),
        active: AlertManagerActiveRoute.value === 'Alert manager alerts',
      },
      {
        id: 'rules',
        name: t('Rules'),
        active: isActiveRulesRoute,
      },
      {
        id: 'actions',
        name: t('Actions'),
        active: AlertManagerActiveRoute.value === 'Alert actions',
      },
    ];
    if (!showHiddenFeaturesRef.value) {
      items = items.filter((item) => item.id === 'actions');
    }
    return items;
  }

  const AlertManagerNavigationData = reactive({
    items: computed(() => {
      return {
        icon: '$icon_alert_manager',
        name: t('Alert Manager'),
        active:
          ALERT_MANAGER_SUB_ROUTES.includes(AlertManagerActiveRoute.value) ||
          router.currentRoute.meta?.alertManagerSubRoute,
        route: showHiddenFeaturesRef.value ? 'Alert manager alerts' : 'Alert actions',
        subItems: getAlertManagerSubmenuItems(),
      };
    }),
  });

  const handleAlertManagerChildrenRoutes = (id: string) => {
    let targetRoute = '';
    switch (id) {
      case 'actions': {
        targetRoute = '/alert-manager/actions';
        break;
      }
      case 'alerts': {
        targetRoute = '/alert-manager/alerts';
        break;
      }
      case 'rules': {
        targetRoute = '/alert-manager/rules';
        break;
      }
      default:
        return false;
    }

    if (router.currentRoute.path !== targetRoute) {
      router.push(targetRoute);
    }

    return true;
  };

  return { AlertManagerNavigationData, handleAlertManagerChildrenRoutes, ALERT_MANAGER_SUB_ROUTES };
}

export default useAlertManagerNavigation;
