// https://developer.eagleeyenetworks.com/docs/iframes
import router from './router';
import api from '@eencloud/eewc-components/src/service/api';

export default function useIframeAuth() {
  function renewTokenViaIframeAncestor(path: any) {
    window.$cookies.remove('access_token');
    window.$cookies.remove('auth_key');
    if (window.top && window.top !== window.self) {
      window.addEventListener(
        'message',
        (event) => {
          if (event.data.type === 'een-token') {
            window.$cookies.set('access_token', event.data.token, '1d', '/', '', true, 'None', true);
            window.$cookies.set('auth_key', event.data.token, '1d', '/', '', true, 'None'), true;

            // Iframe integrators pass the token via the message event
            // We used to call clientSettings to get the base url, but are now getting the base url from the oauth token call
            // To keep the iframe compatible with current integrations, we extract the base url from the token
            // We need to find a better way, because this is not a good practice
            const baseUrl = retrieveBaseUrlFromJWT(event.data.token);
            window.$cookies.set('root_url', baseUrl, '1d', '/', '', true, 'None', true);
            api.token = event.data.token;
            api.baseURL = baseUrl;
            api.getMediaCookie();

            router.replace(path);
          }
        },
        { once: true }
      );
      window.top.postMessage('een-iframe-loaded', '*');
    }
  }

  function retrieveBaseUrlFromJWT(jwt?: string) {
    try {
      if (!jwt) throw new Error('JWT is not set');
      const base64Url = jwt.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      const dataJWT = JSON.parse(window.atob(base64));
      return `https://api.${dataJWT.vms_cluster}.eagleeyenetworks.com/api/v3.0`;
    } catch (err) {
      console.error('Error while getting base url from jwt - ', err);
      return 'https://api.c000.eagleeyenetworks.com/api/v3.0';
    }
  }

  return { renewTokenViaIframeAncestor };
}
