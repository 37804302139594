import { ref, Ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';

import api from '@eencloud/eewc-components/src/service/api';
import { EventMetricsGetResponse, GetEventMetricsParams } from '@eencloud/eewc-components/src/service/api-types';

import { QueryKeys } from '@/queries';

export const useGetEventMetrics = (params: Ref<GetEventMetricsParams>, enabled: Ref<boolean> = ref(true)) => {
  const { actor, eventType } = params.value;
  return useQuery<EventMetricsGetResponse[] | undefined, Error>({
    queryKey: [QueryKeys.GET_EVENT_METRICS, actor, eventType],
    queryFn: () => api.getEventMetrics(params.value) as unknown as EventMetricsGetResponse[],
    enabled,
    retry: true,
  });
};
