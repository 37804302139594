import { defineStore } from 'pinia';
import { ref } from 'vue';
import api from '@eencloud/eewc-components/src/service/api';
import { ApiAvailableDevice, GetAvailableDevicesParams } from '@eencloud/eewc-components/src/service/api-types';

export const useAvailableDevicesStore = defineStore('availableDevices', function () {
  const availableDevices = ref<ApiAvailableDevice[]>([]);
  const loadingAvailableDevices = ref<boolean | undefined>(undefined);
  const getters = {
    availableDevice: (guid: string) => availableDevices.value.find((device) => device.guid === guid),
  };

  async function getAvailableDevices(params: GetAvailableDevicesParams) {
    try {
      let totalSize: number | undefined;
      let pageToken: string | undefined;
      let newValue: ApiAvailableDevice[] = [];
      loadingAvailableDevices.value = true;
      while (pageToken !== '') {
        const data = await api.getAvailableDevices({
          pageToken: pageToken as string,
          pageSize: 500,
          ...params,
        });
        if (data) {
          newValue = newValue.concat(data.results as ApiAvailableDevice[]);
          totalSize = data.totalSize;
          pageToken = data.nextPageToken;
        } else break;
      }
      availableDevices.value = newValue;
    } catch (error) {
      console.error(error);
    }
    loadingAvailableDevices.value = false;
  }

  async function getPagedAvailableDevices(params: GetAvailableDevicesParams) {
    let availableDevices: ApiAvailableDevice[] = [];
    let nextPageToken = '';
    let totalSize = 0;
    try {
      const data = await api.getAvailableDevices(params);
      if (data) {
        nextPageToken = data?.nextPageToken ?? '';
        totalSize = data.totalSize ?? 0;
        availableDevices = data.results as ApiAvailableDevice[];
      }
    } catch (error) {
      console.error(error);
    }
    return { availableDevices, totalSize, nextPageToken };
  }

  async function createTunnel(deviceId: string) {
    const device = getters.availableDevice(deviceId);
    if (!device) return;
    return await api.createDeviceTunnel(deviceId, 'availableDevices', { bridgeId: device.visibleByBridges[0] });
  }

  async function deleteTunnel(deviceId: string) {
    return await api.deleteDeviceTunnel(deviceId, 'availableDevices');
  }

  return {
    ...getters,
    availableDevices,
    getAvailableDevices,
    getPagedAvailableDevices,
    loadingAvailableDevices,
    createTunnel,
    deleteTunnel,
  };
});
