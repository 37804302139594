import { SeriesOptionsType } from 'highcharts';

const seriesBaseConfig: SeriesOptionsType = {
  type: 'column',
  pointPadding: 0.15,
  groupPadding: 0.15,
  turboThreshold: 20000,
  stacking: 'normal',
  showInNavigator: true,
  showInLegend: true,
  dataGrouping: {
    enabled: true,
    approximation: 'sum',
    dateTimeLabelFormats: {
      minute: ['%m-%d %H:%M', '%m-%d %H:%M', '-%H:%M'],
      hour: ['%Y-%m-%d %H:%M', '%m-%d %H:%M', '-%H:%M'],
      day: ['%m-%d', '%m-%d', '-%m-%d'],
      month: ['%Y-%m', '%Y-%m', '-%Y-%m'],
      year: ['%Y', '%Y', '-%Y'],
    },
    groupPixelWidth: 100,
    forced: true,
    units: [
      ['minute', [60]],
      ['hour', [1, 3, 6, 12]],
      ['day', [1]],
    ],
  },
};

export default seriesBaseConfig;
