export function useGTM(gtmId = '') {
  if (!gtmId || typeof window === 'undefined') {
    return;
  }

  // Create the GTM script
  const script = document.createElement('script');
  script.id = `gtm-script-${gtmId}`;
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;

  // Add the script to the document head
  document.head.appendChild(script);

  // Initialize the dataLayer if not already present
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  });
}
