<template>
  <div class="grouped-options">
    <div
      v-for="(option, index) in options"
      :key="index"
      class="option-parent"
    >
      <a
        :class="{ active: selectedOption === option.value }"
        @click="handleClick(option.value)"
      >
        {{ option.label }}
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  selectedOption: {
    type: Number,
    required: true,
  },
  options: {
    type: Array<{ label: string; value: number }>,
    required: true,
  },
});
const emit = defineEmits(['update:handleSelect']);

const handleClick = (value: number) => {
  emit('update:handleSelect', value);
};
</script>

<style lang="scss" scoped>
@import '@/styles/public/main.scss';

.grouped-options {
  display: flex;
  padding: 8px 0px;
  border-radius: 4px;
  border: 1px solid $elements;
  background-color: $backgrounds;
  height: 36px;
  align-items: center;
}

.grouped-options a {
  @include subtitle-3;
  flex: 1;
  margin: 0 8px;
  border: none;
  border-radius: 4px;
  text-align: center;
  background-color: transparent;
  cursor: pointer;
  line-height: 20px !important;
}

.grouped-options a.active {
  background-color: $accentLight;
  color: $accent;
}

.grouped-options a:hover {
  background-color: $accentLight;
}

.grouped-options .option-parent {
  display: flex;
  width: 62px;
}

.grouped-options .option-parent:not(:last-child) {
  border-right: 1px solid $elements;
}
</style>
