import { Item } from '@eencloud/eewc-components/src/components/dropdowns/types';

export type RuleInfo = {
  ruleName: string;
  priority: Item;
  notes: string;
  isEnabled: boolean;
  alertType: Item;
  when: Item;
  eventsDuration: string | Date;
  sites: Item[];
  cameras: Item[];
  actions: Item[];
  days: Item[];
  discountAmountPercentage: string;
  totalAmount: string;
  from: Date;
  till: Date;
};

export enum TransactionType {
  purchase = 'purchase',
  refund = 'refund',
  void = 'void',
}
