import { ref, Ref } from 'vue';

import { useQuery } from '@tanstack/vue-query';

import api from '@eencloud/eewc-components/src/service/api';
import { EventTypeRequestParams, PaginatedEventTypesResponse } from '@eencloud/eewc-components/src/service/api-types';

import { QueryKeys } from '@/queries';

export const useGetSystemEventTypes = (params: EventTypeRequestParams, enabled: Ref<boolean> = ref(true)) => {
  return useQuery<PaginatedEventTypesResponse | undefined>({
    queryKey: [QueryKeys.GET_SYSTEM_SUPPORTED_EVENTS],
    queryFn: async () => api.getEventTypes(params),
    enabled,
  });
};
