import { defineStore } from 'pinia';
import { ref } from 'vue';
import { Route } from 'vue-router';

type TimeFormat = '24h' | '12h';

type TopBarBreadcrumbs =
  | {
      from: string;
      title: string;
      backEvent: () => void;
    }
  | undefined;

export const useAppStateStore = defineStore('appState', function () {
  const timeFormat = ref<TimeFormat>('24h');
  const externalBrowser = ref(false);
  const topBarBreadcrumbs = ref<TopBarBreadcrumbs>();
  const showHiddenFeatures = ref<boolean>(false);
  const isFullScreen = ref<boolean>(false);
  const searchQuery = ref<string>('');
  const currentRoute = ref<Route>();
  const isIFrame = ref(window.self !== window.top);
  const iframeAuthenticated = ref(false);
  const routerHistoryItems = ref<Route[]>([]);

  const actions = {
    updateCurrentRoute(route: Route) {
      currentRoute.value = route;
    },
    updateSearchQuery(query: string) {
      searchQuery.value = query.trim();
    },
    changeTimeFormat(format: TimeFormat) {
      timeFormat.value = format;
    },
    updateIsFullScreen(newValue: boolean) {
      isFullScreen.value = newValue;
    },
    changeTopBarBreadcrumbs(newTopBarBreadcrumbs: TopBarBreadcrumbs) {
      topBarBreadcrumbs.value = newTopBarBreadcrumbs;
    },
    updateShowHiddenFeatures(state: boolean) {
      showHiddenFeatures.value = state;
    },
  };

  return {
    timeFormat,
    topBarBreadcrumbs,
    externalBrowser,
    showHiddenFeatures,
    isFullScreen,
    searchQuery,
    currentRoute,
    isIFrame,
    iframeAuthenticated,
    routerHistoryItems,
    ...actions,
  };
});
