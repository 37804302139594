import { defineStore } from 'pinia';
import { reactive, ref, set, del } from 'vue';
import { generateTimestamp } from '@/service/helpers';
import api from '@eencloud/eewc-components/src/service/api';
import {
  ApiMediaWithIncludes,
  mediaMainRequest,
  ApiPaginatedMediaResponse,
  ApiCameraWithIncludes,
} from '@eencloud/eewc-components/src/service/api-types';
import { useEventsStore } from '@/stores';
import type { CameraMediaStore } from '@/pages/HistoryBrowser/historyBrowserTypes';
import { calculateTimeRange, getElementsForDesiredTimeRange } from '@/pages/HistoryBrowser/historyBrowserHelpers';
import { EVENTS_API_CHUNK_SIZE, MEDIA_API_CHUNK_SIZE, DAY } from '@/pages/HistoryBrowser/constants';

interface RequestConfig {
  returnResults?: boolean;
  updatePendingRecordings?: boolean;
  deviceId: string;
  startTimestamp: string;
  endTimestamp?: string;
  customPageSize?: number;
  include?: string;
  type?: 'preview' | 'main';
  mediaType?: 'video' | 'image';
  coalesce?: boolean;
}

const INITIAL_BROWSE_BACK_VALUE = 12000;
const EXPECTED_COVERAGE = 2 * DAY; // expected coverage for events and recordings

export const useHistoryBrowserStore = defineStore('historyBrowser', () => {
  const eventsStore = useEventsStore();
  const currentTime = ref(Date.now() - INITIAL_BROWSE_BACK_VALUE); // gets updated by releasing a drag gesture or by the html video player progression
  const cameraIds = ref<string[]>([]);
  const compositeIds = ref<{ [key: string]: string }>({});
  const stores = reactive<{ [key: string]: CameraMediaStore }>({});
  const lastCameraIds = ref<string[]>([]);
  const sideBarOpen = ref(false);
  const primaryCameraId = ref<string>(cameraIds.value[0]);
  const selectedVideoTile = ref<string | undefined>();
  const loadingMedia = ref(false);
  const loadingEvents = ref(false);
  const customOrder = ref<string[] | null | undefined>();
  const exportMode = ref(false);
  const exportCurrentTime = ref(0); // represents either the start or end time of the export dependent on the selected side handle of the selector
  const eventSearchPopOver = ref(false);
  const visibleCamerasWhichHasRecordings = ref<string[]>([]);
  const visibleCamerasWhichHasEvents = ref<string[]>([]);
  // for MultiPlaybackSidebar.vue && VideoGrid.vue to modify the infinite loading cameras
  const infiniteLoadingCameras = ref<ApiCameraWithIncludes[]>([]);
  const daySelectorExpanded = ref(false);
  const playOnLoad = ref<boolean>(false);
  const infiniteLoadingCamerasNextPageToken = ref<string | undefined>(undefined);
  const mediaMainUpdatedTimestamp = ref<number>(0);
  const mediaPreviewUpdatedTimestamp = ref<number>(0);
  const minimumAvailableDateEpoch = ref<number | undefined>();
  const terminateFetchLoop = ref(false); // used to terminate fetch loops when the component is unmounted
  const videoOverlayTimestamps = ref<{ id: string; timeStampUnix: number }[]>([]);
  const selectedRecordings = reactive<{ [key: string]: ApiMediaWithIncludes | null }>({});
  const resolutionSetting = ref<'main' | 'preview'>('main');
  const previewImagePageTokens: { [key: string]: { timestamp: number; prevToken: string; nextToken: string } } = {};
  const playbackSpeed = ref(1);
  // key is cameraId, value is svg string
  const zoomedCameras = reactive<{ [key: string]: string }>({});

  function addZoomedCameras(cameraId: string, svg: string) {
    set(zoomedCameras, cameraId, svg);
  }

  function removeZoomedCameras(cameraId: string) {
    del(zoomedCameras, cameraId);
  }

  // set infinite loading cameras for MultiPlaybackSidebar.vue && VideoGrid.vue
  function setInfiniteLoadingCameras(cameras: ApiCameraWithIncludes[]) {
    infiniteLoadingCameras.value = cameras;
  }

  async function updateLastCameraIds(newIds: string[]) {
    lastCameraIds.value = newIds;
  }

  function setComposite(cameraId: string, compositeId: string) {
    compositeIds.value[cameraId] = compositeId;
  }

  async function getEvents(cameraId: string, currentTime: number) {
    const dateNow = Date.now();

    if (!stores[cameraId]) {
      addNewCameraToStore(cameraId);
    }

    stores[cameraId].expectedCoverage = calculateTimeRange(currentTime, EXPECTED_COVERAGE, dateNow);
    stores[cameraId].currentTime = currentTime;

    if (stores[cameraId].fetchingEvents) return;

    getElementsForDesiredTimeRange(
      cameraId,
      stores[cameraId],
      eventsStore.fetchEvents,
      dateNow,
      generateTimestamp,
      EVENTS_API_CHUNK_SIZE,
      {
        addToVisibleCameras: addToVisibleCamerasWhichHasEvents,
        removeFromVisibleCameras: removeFromVisibleCamerasWhichHasEvents,
        isIncludeVisibleCameras: isInVisibleCamerasWhichHasEvents,
      },
      'events',
      undefined,
      terminateFetchLoop
    );
  }

  function getRecordings(cameraId: string, currentTime: number, updatePendingRecordings = false) {
    const dateNow = Date.now();

    if (!stores[cameraId]) {
      addNewCameraToStore(cameraId);
    }

    stores[cameraId].expectedCoverage = calculateTimeRange(currentTime, EXPECTED_COVERAGE, dateNow);
    stores[cameraId].currentTime = currentTime;

    if (stores[cameraId].fetchingMainMedia) return;

    getElementsForDesiredTimeRange(
      cameraId,
      stores[cameraId],
      getMainMedia,
      dateNow,
      generateTimestamp,
      MEDIA_API_CHUNK_SIZE,
      undefined,
      'medias',
      updatePendingRecordings,
      terminateFetchLoop
    );
  }

  function addNewCameraToStore(cameraId: string) {
    stores[cameraId] = {
      mediaMain: [],
      mediaPreview: [],
      events: [],
      loadingRecordedImage: false,
      currentTime: 0,
      expectedCoverage: {
        start: 0,
        end: 0,
      },
      eventsCurrentCoverage: {
        start: 0,
        end: 0,
      },
      mainMediaCurrentCoverage: {
        start: 0,
        end: 0,
      },
      previewMediaCurrentCoverage: {
        start: 0,
        end: 0,
      },
      allEventsInRangeLoaded: false,
      fetchingEvents: false,
      fetchingMainMedia: false,
      fetchingPreviewMedia: false,
      allMediaInRangeLoaded: false,
      allPreviewMediaInRangeLoaded: false,
    };
  }

  function removeCameraFromStore(cameraId: string) {
    if (stores[cameraId]) {
      delete stores[cameraId];
    }
  }

  async function fetchMedia(requestPayload: mediaMainRequest): Promise<ApiPaginatedMediaResponse | undefined> {
    return await api.getMedia(requestPayload);
  }

  async function getPreviewMedia(requestConfig: RequestConfig) {
    const cameraMediaStore = stores[requestConfig.deviceId];
    const previewSpecificRequestConfig = {
      deviceId: requestConfig.deviceId,
      startTimestamp__gte: requestConfig.startTimestamp,
      endTimestamp__lte: requestConfig.endTimestamp,
      type: 'preview',
      mediaType: 'video',
      include: undefined,
      coalesce: true,
      pageToken: undefined as string | undefined,
      pageSize: requestConfig.customPageSize ?? 500,
    };
    let apiCallSuccessful = false;

    const returnValue: ApiMediaWithIncludes[] = [];

    do {
      try {
        const data = await fetchMedia(previewSpecificRequestConfig);
        if (data && data.results) {
          const results = data.results;
          // updatePendingRecordings is used when polling for new recordings. In this case, we want to overwrite pending recordings so their endTimestamp is updated.
          if (requestConfig.updatePendingRecordings) {
            results.forEach((res) => {
              const found = cameraMediaStore.mediaPreview.findIndex(
                (media) => media.startTimestamp === res.startTimestamp
              );
              if (found !== -1) {
                cameraMediaStore.mediaPreview.splice(found, 1, res as ApiMediaWithIncludes);
              } else {
                cameraMediaStore.mediaPreview.unshift(res as ApiMediaWithIncludes);
              }
            });
          } else {
            if (requestConfig.returnResults) {
              returnValue.push(...(results as ApiMediaWithIncludes[]));
            } else if (results.length) {
              cameraMediaStore.mediaPreview.unshift(...(results as ApiMediaWithIncludes[]));
            }
          }

          previewSpecificRequestConfig.pageToken = data.nextPageToken;
          if (results) mediaPreviewUpdatedTimestamp.value = Date.now();
          apiCallSuccessful = true;
        } else {
          apiCallSuccessful = false;
          break;
        }
      } catch (error) {
        apiCallSuccessful = false;
        console.error(error);
      }
    } while (previewSpecificRequestConfig.pageToken && !requestConfig.customPageSize && !terminateFetchLoop.value); // if customPageSize is not defined, it will fetch all media with pageSize= 500
    return requestConfig.returnResults ? returnValue : apiCallSuccessful;
  }

  async function getMainMedia(requestConfig: RequestConfig) {
    // we should reuse this method when we want get other media types
    let apiCallSuccessful = false;
    const cameraMediaStore = stores[requestConfig.deviceId];
    loadingMedia.value = true;

    const requestPayload = {
      deviceId: requestConfig.deviceId,
      startTimestamp__gte: requestConfig.startTimestamp,
      endTimestamp__lte: requestConfig.endTimestamp,
      pageToken: undefined as string | undefined,
      pageSize: requestConfig.customPageSize ?? 500,
      type: requestConfig.type ?? 'main',
      mediaType: requestConfig.mediaType ?? 'video',
      include: requestConfig.include,
      coalesce: requestConfig.coalesce ?? true,
    };

    const returnValue: ApiMediaWithIncludes[] = [];
    do {
      try {
        const data = await fetchMedia(requestPayload);
        if (data && data.results) {
          const results = data.results;
          // updatePendingRecordings is used when polling for new recordings. In this case, we want to overwrite pending recordings so their endTimestamp is updated.
          if (requestConfig.updatePendingRecordings) {
            results.forEach((res) => {
              const found = cameraMediaStore.mediaMain.findIndex(
                (media) => media.startTimestamp === res.startTimestamp
              );
              if (found !== -1) {
                cameraMediaStore.mediaMain.splice(found, 1, res as ApiMediaWithIncludes);
              } else {
                cameraMediaStore.mediaMain.unshift(res as ApiMediaWithIncludes);
              }
            });
          } else {
            if (requestConfig.returnResults) {
              returnValue.push(...(results as ApiMediaWithIncludes[]));
            } else if (results.length) {
              cameraMediaStore.mediaMain.unshift(...(results as ApiMediaWithIncludes[]));
            }
          }
          requestPayload.pageToken = data.nextPageToken;
          if (results) mediaMainUpdatedTimestamp.value = Date.now();
          apiCallSuccessful = true;
        } else {
          apiCallSuccessful = false;
          break;
        }
      } catch (error) {
        apiCallSuccessful = false;
        console.error(error);
      }
    } while (requestPayload.pageToken && !requestConfig.customPageSize && !terminateFetchLoop.value); // if customPageSize is not defined, it will fetch all media with pageSize= 500

    if (!import.meta.env.PROD) {
      const duplicates = cameraMediaStore.mediaMain.filter(
        (currentValue, currentIndex) =>
          cameraMediaStore.mediaMain.findIndex((i) => i.startTimestamp === currentValue.startTimestamp) !== currentIndex
      );
      if (duplicates.length) {
        console.log('duplicate media detected:', duplicates);
      }
    }
    loadingMedia.value = false;
    return requestConfig.returnResults ? returnValue : apiCallSuccessful;
  }

  function updateExportMode(value: boolean) {
    exportMode.value = value;
  }

  function resetCurrentTime() {
    currentTime.value = Date.now() - INITIAL_BROWSE_BACK_VALUE;
  }

  function removeFromVisibleCamerasWhichHasEvents(cameraId: string) {
    visibleCamerasWhichHasEvents.value = visibleCamerasWhichHasEvents.value.filter((id) => id !== cameraId);
  }

  function addToVisibleCamerasWhichHasEvents(cameraId: string) {
    visibleCamerasWhichHasEvents.value = [...visibleCamerasWhichHasEvents.value, cameraId];
  }

  function isInVisibleCamerasWhichHasEvents(cameraId: string) {
    return visibleCamerasWhichHasEvents.value.includes(cameraId);
  }

  function removeFromVisibleCamerasWhichHasRecordings(cameraId: string) {
    visibleCamerasWhichHasRecordings.value = visibleCamerasWhichHasRecordings.value.filter((id) => id !== cameraId);
  }

  function addToVisibleCamerasWhichHasRecordings(cameraId: string) {
    visibleCamerasWhichHasRecordings.value = [...visibleCamerasWhichHasRecordings.value, cameraId];
  }

  function isInVisibleCamerasWhichHasRecording(cameraId: string) {
    return visibleCamerasWhichHasRecordings.value.includes(cameraId);
  }

  function resetMultiCameraSidebar() {
    infiniteLoadingCamerasNextPageToken.value = undefined;
    setInfiniteLoadingCameras([]);
    sideBarOpen.value = false;
  }

  function terminateFetchLoops() {
    eventsStore.terminateFetchEventLoops();
    terminateFetchLoop.value = true;
  }

  function renderTimestamp(cameraId: string, timeStampUnix: number) {
    videoOverlayTimestamps.value = videoOverlayTimestamps.value.filter((ts) => ts.id !== cameraId);
    videoOverlayTimestamps.value.push({ id: cameraId, timeStampUnix });
  }

  return {
    getMainMedia,
    getPreviewMedia,
    stores,
    lastCameraIds,
    currentTime,
    removeCameraFromStore,
    getRecordings,
    sideBarOpen,
    cameraIds,
    compositeIds,
    primaryCameraId,
    selectedVideoTile,
    loadingMedia,
    getEvents,
    updateLastCameraIds,
    loadingEvents,
    addNewCameraToStore,
    visibleCamerasWhichHasRecordings,
    visibleCamerasWhichHasEvents,
    customOrder,
    updateExportMode,
    exportMode,
    exportCurrentTime,
    fetchMedia,
    eventSearchPopOver,
    resetCurrentTime,
    infiniteLoadingCameras,
    setInfiniteLoadingCameras,
    daySelectorExpanded,
    playOnLoad,
    infiniteLoadingCamerasNextPageToken,
    resetMultiCameraSidebar,
    mediaMainUpdatedTimestamp,
    mediaPreviewUpdatedTimestamp,
    terminateFetchLoops,
    terminateFetchLoop,
    renderTimestamp,
    setComposite,
    minimumAvailableDateEpoch,
    videoOverlayTimestamps,
    selectedRecordings,
    resolutionSetting,
    previewImagePageTokens,
    playbackSpeed,
    zoomedCameras,
    addZoomedCameras,
    removeZoomedCameras,
  };
});
