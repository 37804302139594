import { defineStore } from 'pinia';
import { ref } from 'vue';
import { logOut, rerouteToOAuthLogin as reRouteToLogin } from '@eencloud/eewc-components/src/service/auth.js';
import { usePersistedStateStore } from './persistedState';

export const useAuthStateStore = defineStore('authState', function () {
  const loggedIn = ref(false);
  const persistedStore = usePersistedStateStore();

  function loginSuccessful() {
    loggedIn.value = true;
  }

  function logout() {
    loggedIn.value = false;
    persistedStore.clearPersistedState();
    logOut();
  }

  /**
   * Logs out the user, clears persisted state, and redirects to the OAuth login page.
   *
   * This function sets the `loggedIn` status to `false`, clears any stored data in the
   * persisted store, and then redirects the user to the OAuth login route for re-authentication.
   */
  function rerouteToOAuthLogin() {
    loggedIn.value = false;
    persistedStore.clearPersistedState();
    reRouteToLogin();
  }

  return {
    loggedIn,
    loginSuccessful,
    logout,
    rerouteToOAuthLogin,
  };
});
