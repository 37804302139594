import { useUsersStore } from '@/stores';
import { toEENTimestamp } from '@/service/timeHelpers';
import type { DateFormatterParams } from '@/service/timeHelpers';

Date.prototype.toEENAccountTimeStamp = function (params?: DateFormatterParams) {
  const {
    timezone = useUsersStore().currentUserTimezone,
    showMilliseconds = useUsersStore().currentUserTimeSettings?.showMilliseconds,
    hour12 = useUsersStore().hour12,
    showDate,
    showTime,
  } = params ?? {
    timezone: useUsersStore().currentUserTimezone,
    showMilliseconds: useUsersStore().currentUserTimeSettings?.showMilliseconds,
    hour12: useUsersStore().hour12,
  };

  return toEENTimestamp({
    date: this,
    timezone,
    showMilliseconds,
    hour12,
    showDate,
    showTime,
  });
};

Date.prototype.toEENCameraDateTimeStamp = function (params?: DateFormatterParams) {
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const {
    timezone = localTimezone,
    showMilliseconds = useUsersStore().currentUserTimeSettings?.showMilliseconds,
    hour12 = useUsersStore().hour12,
    showDate,
    showTime,
  } = params ?? {
    timezone: localTimezone,
    showMilliseconds: useUsersStore().currentUserTimeSettings?.showMilliseconds,
    hour12: useUsersStore().hour12,
  };
  return toEENTimestamp({
    date: this,
    timezone,
    showMilliseconds,
    hour12,
    showDate,
    showTime,
  });
};

/**
 *
 * @returns the date in the format 2022-08-01T07:17:55.115+00:00
 */
Date.prototype.toEENApiDateTime = function () {
  return this.toISOString().replace('Z', '+00:00');
};

export function getGmtTimestamp(val: string) {
  const date = new Date(val);
  const gmt = (date.getTimezoneOffset() * -1) / 60;
  const usersStore = useUsersStore();
  const eenDateTime = date.toEENCameraDateTimeStamp({ timezone: usersStore.currentUserTimezone }).split(' ');
  return {
    dateInfo: eenDateTime[0],
    timeInfo: `${eenDateTime[1].split('.')[0]} ${eenDateTime[2] ?? ''} (GMT${gmt >= 0 ? '+' : ''}${gmt})`,
    time: `${eenDateTime[1].split('.')[0]} ${eenDateTime[2] ?? ''}`,
  };
}
