<template>
  <eewc-notification-dialog
    v-if="messagingStore.notification"
    :icon-color="messagingStore.notification.iconColor"
    :icon="messagingStore.notification.icon"
    :title="messagingStore.notification.title"
    :body="messagingStore.notification.body"
    @closeNotificationDialog="messagingStore.removeNotification()"
  >
    <template #action>
      <eewc-button-common
        v-if="messagingStore.notification.actionBtnText"
        class="notification__action-button"
        :color="messagingStore.notification.actionBtnColor"
        type="clear"
        @click="onActionBtnClick"
      >
        {{ messagingStore.notification.actionBtnText }}
      </eewc-button-common>
    </template>
  </eewc-notification-dialog>
</template>

<script setup lang="ts">
import { useMessagingStore } from '@/stores';
const messagingStore = useMessagingStore();

function onActionBtnClick() {
  messagingStore.notification?.onActionBtnClick && messagingStore.notification.onActionBtnClick();
  messagingStore.removeNotification();
}
</script>

<style scoped lang="scss">
@import '@eencloud/eewc-components/src/assets/styles/colors';

.notification__action-button {
  margin-left: -16px;

  //temporary fix for accent button until is not fully ready.
  &.een-button.accentBtn {
    &:focus {
      border-color: $accent !important;
    }
    &:hover {
      background-color: $primaryMedium !important;
    }
    &:active {
      color: $accentDark !important;
      background-color: $primary !important;
    }
  }
}
</style>
