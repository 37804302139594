import { t, tc } from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify';
import { computed } from 'vue';

export const ONE_HOUR_INTERVAL = 1;
export const SIX_HOUR_INTERVAL = 6;
export const TWELVE_HOUR_INTERVAL = 12;
export const TWENTY_FOUR_HOUR_INTERVAL = 24;
export const MAX_ALLOWED_RETENTION_DAYS = 60;

export const COLOR_FOR_ANALYTIC_EVENT = [vuetify.framework.theme.themes.light.analytics];

export const GROUPING_OPTIONS = computed(() => [
  { label: tc('{hour} Hr', ONE_HOUR_INTERVAL, { hour: ONE_HOUR_INTERVAL }), value: ONE_HOUR_INTERVAL },
  { label: tc('{hour} Hr', SIX_HOUR_INTERVAL, { hour: SIX_HOUR_INTERVAL }), value: SIX_HOUR_INTERVAL },
  { label: tc('{hour} Hr', TWELVE_HOUR_INTERVAL, { hour: TWELVE_HOUR_INTERVAL }), value: TWELVE_HOUR_INTERVAL },
  {
    label: tc('{hour} Hr', TWENTY_FOUR_HOUR_INTERVAL, { hour: TWENTY_FOUR_HOUR_INTERVAL }),
    value: TWENTY_FOUR_HOUR_INTERVAL,
  },
]);

export const REQUIRED_EVENT_TYPE_LIST = [
  'een.motionDetectionEvent.v1',
  'een.loiterDetectionEvent.v1',
  'een.tamperDetectionEvent.v1',
  'een.objectIntrusionEvent.v1',
  'een.objectLineCrossEvent.v1',
];

export const DROP_DOWN_MAP = {
  'een.motionDetectionEvent.v1': t('Motion'),
  'een.loiterDetectionEvent.v1': t('Loiter'),
  'een.tamperDetectionEvent.v1': t('Tampering'),
  'een.objectIntrusionEvent.v1': t('Intrusion'),
};

export const LINE_CROSSING_EVENT = [
  { text: t('Object Line Cross in'), value: 'line-cross-in' },
  { text: t('Object Line Cross out'), value: 'line-cross-out' },
];

export const LINE_CROSSING_IN = 'line-cross-in';
export const LINE_CROSSING_OUT = 'line-cross-out';

export const LINE_CROSS_EVENT_TYPE = 'een.objectLineCrossEvent.v1';
