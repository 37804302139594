import api from '@eencloud/eewc-components/src/service/api';
import {
  Notification,
  NotificationParams,
  UpdateNotification,
  NotificationActor,
  NotificationActorsParams,
} from '@eencloud/eewc-components/src/service/api-types';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import usePagination from '@/service/usePagination';
import { t } from '@/plugins/i18n.ts';
import { useMessagingStore } from './messaging';
import { useUsersStore } from './users';

export const useNotificationsStore = defineStore('notifications', function () {
  const defaultPageSize = 25;
  const messagingStore = useMessagingStore();
  const notifications = ref<Notification[]>([]);
  const notificationActors = ref<NotificationActor[]>([]);
  const { updatePaginationData, paginationParams, calculateCurrentTotalSize } = usePagination();
  const loadingNotifications = ref(true);
  const loadingNotificationActors = ref(true);
  const newNotification = ref(false);
  const pageSize = ref<number>(defaultPageSize);
  const totalSize = ref<number | undefined>(0);
  const nextPageToken = ref<string | undefined>();
  const prevPageToken = ref<string | undefined>();
  const recentNotifications = ref<Notification[]>([]);
  const recentLoadingNotifications = ref(false);

  async function getAllNotifications(payload: NotificationParams) {
    try {
      loadingNotifications.value = true;
      const data = await api.getNotifications(payload);
      if (data) {
        notifications.value = data.results as Notification[];
      }
    } catch (error) {
      console.error(error);
    }
    loadingNotifications.value = false;
  }

  async function getSpecificNotifications() {
    try {
      const userId = useUsersStore().currentUser?.id;
      if (!userId) {
        return;
      }
      recentLoadingNotifications.value = true;
      const params = { userId, pageSize: 15 };
      const data = await api.getNotifications(params);
      if (data) {
        if (data.results?.length) {
          const lastNotification = window.$cookies.get('vms_notification_timestamp');
          const cookieDate = new Date(lastNotification);
          const newNotificationDate = new Date(data.results[0].timestamp ?? '');
          if (newNotificationDate > cookieDate) {
            const today = new Date();
            const seconds = today.setFullYear(today.getFullYear() + 1);
            window.$cookies.set(
              'vms_notification_timestamp',
              data.results[0].timestamp,
              seconds + 's',
              'eagleeyenetworks.com'
            ); // setting expiration period to 1 year from now
            newNotification.value = true;
          }
        }
        recentNotifications.value = data.results ?? [];
      }
    } catch (error) {
      console.error(error);
    }
    recentLoadingNotifications.value = false;
  }

  async function updateRecentNotification(id: string, payload: UpdateNotification, shouldShowNotification = true) {
    try {
      const response = await api.updateNotification({
        notificationId: id,
        payload,
      });
      if (!response || response !== 204) {
        throw new Error();
      }
      if (shouldShowNotification) {
        messagingStore.addNotification({
          iconColor: 'positive',
          icon: '$icon_check_zero',
          title: payload.read ? t('Marked read successfully') : t('Marked unread successfully'),
          body: payload.read ? t('Selected notification marked read') : t('Selected notification marked unread'),
        });
      }
      recentNotifications.value = recentNotifications.value.map((notification) => {
        return notification.id === id ? { ...notification, read: payload.read } : notification;
      });
    } catch (error) {
      console.error(error);
    }
    return id;
  }

  async function updateNotification(id: string, payload: UpdateNotification, shouldShowNotification = true) {
    const messagingStore = useMessagingStore();
    try {
      const response = await api.updateNotification({
        notificationId: id,
        payload,
      });
      if (!response || response !== 204) {
        throw new Error();
      }
      if (shouldShowNotification) {
        messagingStore.addNotification({
          iconColor: 'positive',
          icon: '$icon_check_zero',
          title: payload.read ? t('Marked read successfully') : t('Marked unread successfully'),
          body: payload.read ? t('Selected notification marked read') : t('Selected notification marked unread'),
        });
      }
      notifications.value = notifications.value.map((notification) => {
        return notification.id === id ? { ...notification, read: payload.read } : notification;
      });
    } catch (error) {
      console.error(error);
    }
    return id;
  }

  async function updateNewNotificationState() {
    newNotification.value = false;
  }

  async function getPagedNotification(params: NotificationParams) {
    if (!params.userId) {
      return;
    }
    let notificationsList: Notification[] = [];
    try {
      loadingNotifications.value = true;
      const data = await api.getNotifications({
        pageSize: paginationParams.pageSize,
        ...params,
      });
      if (data) {
        const res = data as {
          results: Notification[];
          totalSize: number;
          nextPageToken: string;
          prevPageToken: string;
        };
        totalSize.value = calculateCurrentTotalSize(data?.nextPageToken, res.results.length);
        nextPageToken.value = res.nextPageToken;
        prevPageToken.value = res.prevPageToken;
        notificationsList = [...res?.results];
      }
    } catch (error) {
      console.error(error);
    }
    loadingNotifications.value = false;
    notifications.value = notificationsList;
  }

  async function getAllNotificationActors() {
    try {
      loadingNotificationActors.value = true;
      notificationActors.value = [];
      let pageToken: string | undefined;

      while (pageToken !== '') {
        const data = await api.getNotificationActors({
          pageToken: pageToken as string,
          pageSize: 500,
        });
        if (data) {
          notificationActors.value = [...notificationActors.value, ...(data.results as NotificationActor[])];
          pageToken = data.nextPageToken;
        } else break;
      }
      loadingNotificationActors.value = false;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    notifications,
    notificationActors,
    loadingNotifications,
    loadingNotificationActors,
    newNotification,
    recentNotifications,
    recentLoadingNotifications,
    getAllNotifications,
    getAllNotificationActors,
    getSpecificNotifications,
    updateNotification,
    updateRecentNotification,
    updateNewNotificationState,
    getPagedNotification,
    updatePaginationData,
    totalSize,
    nextPageToken,
    prevPageToken,
    pageSize,
  };
});
