import { reactive } from 'vue';
import { vuetifyPagination } from '@eencloud/eewc-components/src/utils/types';

export default function usePagination() {
  const paginationParams = reactive({
    pageSize: 25,
    page: 1,
  });
  function getPageToken(
    newPage: number,
    oldPage: number,
    nextPageToken: string | undefined,
    prevPageToken: string | undefined
  ) {
    let pageToken: string | undefined;

    if (newPage === 1) {
      return undefined;
    }

    if (newPage > oldPage) {
      pageToken = nextPageToken;
    }

    if (newPage < oldPage) {
      pageToken = prevPageToken;
    }

    return pageToken;
  }

  function updatePaginationData(pagination: vuetifyPagination) {
    paginationParams.pageSize = pagination.itemsPerPage;
    paginationParams.page = pagination.page;
  }

  function calculateCurrentTotalSize(nextPageToken: string | undefined, dataLength: number) {
    if (nextPageToken && dataLength) {
      return paginationParams.pageSize * paginationParams.page + 1;
    } else {
      return paginationParams.pageSize * (paginationParams.page - 1) + dataLength;
    }
  }
  return {
    getPageToken,
    updatePaginationData,
    paginationParams,
    calculateCurrentTotalSize,
  };
}
