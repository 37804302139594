import { ref, onUnmounted, watch } from 'vue';
import { debounce } from 'lodash';
import { t } from '@/plugins/i18n.ts';
import { useAccountStore, useAuthStateStore, useMessagingStore } from '@/stores';

const SESSION_TIMEOUT_WARNING_DURATION = 30000; // 30 seconds warning duration
const COUNTDOWN_INTERVAL = 1000; // 1 second interval for countdown
const DEBOUNCE_DELAY = 300; // 300ms debounce delay

export default function useInactivityMonitor() {
  const messagingStore = useMessagingStore();
  const accountStore = useAccountStore();
  const authStateStore = useAuthStateStore();

  const isInactive = ref(false);
  const countDownStarted = ref(false);
  const timeout = ref<number>();

  let inactivityTimer: ReturnType<typeof setTimeout>;
  let countdownTimer: ReturnType<typeof setInterval>;

  const debouncedReset = debounce(() => {
    if (!countDownStarted.value) {
      resetInactivityTimer();
    }
  }, DEBOUNCE_DELAY);

  /**
   * Initialize the activity monitor with multiple event listeners
   */
  function initActivityMonitor() {
    resetInactivityTimer();
    document.addEventListener('mousemove', debouncedReset);
    document.addEventListener('keydown', debouncedReset);
  }

  /**
   * Reset the inactivity timer and set the countdown for session timeout
   */
  function resetInactivityTimer() {
    clearTimeout(inactivityTimer);
    clearInterval(countdownTimer);
    isInactive.value = false;
    inactivityTimer = setTimeout(handleInactivity, timeout.value);
  }

  /**
   * Handle user inactivity and show the session timeout confirmation
   */
  function handleInactivity() {
    isInactive.value = true;
  }

  function showSessionTimeoutConfirmation(bodyText: string) {
    messagingStore.showCustomConfirmationDialog({
      title: t('Session Timeout'),
      bodyText: bodyText,
      buttonText: t('Logout'),
      doNotShowNotification: false,
      icon: '$icon_attention',
      iconColor: 'warning',
      confirmBtnColor: 'outlined',
      cancelCallback: () => {
        resetInactivityTimer();
        countDownStarted.value = false;
        clearInterval(countdownTimer);
        return new Promise<void>(() => {});
      },
      callback: () => {
        authStateStore.logout();
        return new Promise<void>(() => {});
      },
    });
  }

  /**
   * Watch for changes in session inactivity timeout and initialize the activity monitor
   */
  watch(
    () => accountStore.account?.security?.sessionInactivityTimeout,
    (newValue, oldValue) => {
      if (!newValue || newValue === oldValue) return;
      const timeoutMs = newValue * 60 * 1000; // Convert minutes to milliseconds
      timeout.value = timeoutMs - SESSION_TIMEOUT_WARNING_DURATION; // Subtract warning duration
      initActivityMonitor();
    }
  );

  /**
   * Watch for inactivity state changes and handle countdown
   */
  watch(isInactive, (newValue) => {
    if (newValue && !countDownStarted.value) startCountdown();
  });

  /**
   * Start the countdown timer for session timeout
   */
  function startCountdown() {
    let remainingTime = SESSION_TIMEOUT_WARNING_DURATION / 1000; // Convert to seconds
    updateCountdown(remainingTime);
    countdownTimer = setInterval(() => {
      remainingTime--;
      if (remainingTime >= 0) {
        updateCountdown(remainingTime);
        countDownStarted.value = true;
      } else {
        clearInterval(countdownTimer);
        countDownStarted.value = false;
        authStateStore.logout();
      }
    }, COUNTDOWN_INTERVAL);
  }

  /**
   * Update the countdown timer and show the session timeout confirmation
   */
  function updateCountdown(remainingTime: number) {
    const remainingTimeText = `00:${remainingTime.toString().padStart(2, '0')}`;
    const modalBodyText = t(
      `Your session is about to expire due to inactivity. You will be logged out in {remainingTime} seconds.`,
      { remainingTime: remainingTimeText }
    );
    showSessionTimeoutConfirmation(modalBodyText);
  }

  /**
   * Clean up on component unmount
   */
  onUnmounted(() => {
    document.removeEventListener('mousemove', debouncedReset);
    document.removeEventListener('keydown', debouncedReset);
    clearTimeout(inactivityTimer);
    clearInterval(countdownTimer);
  });

  return {
    isInactive,
  };
}
