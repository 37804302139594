/**
 * Maximum number of panes allowed in the smart layout.
 * This limit exists because, in order to enable the smart layout,
 * all Muuri items/panes must be rendered beforehand.
 */
export const SMART_LAYOUT_PANES_LENGTH_LIMIT = 100;

/**
 * The duration of one minute, represented in milliseconds.
 * This value is commonly used for time calculations in the application.
 */
export const ONE_MINUTE_IN_MILLISECONDS = 60 * 1000;

/**
 * Represents a camera that was moved within the smart layout,
 * tracking the last time it was moved.
 */
export type MovedCamera = {
  /**
   * Unique identifier of the moved camera.
   */
  id: string;

  /**
   * The timestamp (in milliseconds) of when the camera was last moved.
   */
  lastMovedTime: number;
};

/**
 * Represents a set of pinned camera IDs associated with a layout.
 */
export type PinnedCamerasPerLayout = {
  /**
   * The unique identifier for the layout.
   */
  layoutId: string;

  /**
   * An optional array of camera IDs that are pinned in the layout.
   */
  cameraIds?: string[];
};

/**
 * Type definition for smart layout settings.
 * Represents configuration options for enabling the smart layout feature,
 * setting the duration of events, subscribing to specific events, and pinning cameras.
 */
export type SMART_LAYOUT_SETTINGS = {
  /**
   * Whether the smart layout feature is enabled.
   */
  enable: boolean;

  /**
   * Duration (in minutes) for which events are considered active in the smart layout.
   * Acceptable values are 15, 30, 45, or 60 minutes.
   */
  duration: 15 | 30 | 45 | 60;

  /**
   * List of event types that the layout is subscribed to.
   * Each item in the array is a string identifier of an event type.
   */
  subscribedEvents: string[];

  /**
   * The delay (in milliseconds) for updating the smart layout.
   * This delay is used to prevent excessive updates to the layout.
   * 0 means no delay, and the layout will update as soon as possible.
   */
  updationDelay: number;

  /**
   * An array of pinned camera ids associated with specific layouts.
   */
  pinnedCamerasPerLayout: PinnedCamerasPerLayout[];
};

/**
 * Default settings for the smart layout.
 * Provides default configuration when initializing the smart layout feature.
 * - `enable`: Feature is disabled by default.
 * - `duration`: Set to 15 minutes.
 * - `subscribedEvents`: Empty array, meaning no default event subscriptions.
 * - `pinnedCamerasPerLayout`: Empty array, meaning no default pinned cameras.
 */
export const DEFAULT_SMART_LAYOUT_SETTINGS: SMART_LAYOUT_SETTINGS = {
  enable: false,
  duration: 15,
  subscribedEvents: [],
  updationDelay: 1000,
  pinnedCamerasPerLayout: [],
};

/**
 * List of supported event types for the smart layout.
 * These event types can be subscribed to in the smart layout configuration.
 * - `een.motionDetectionEvent.v1`: Event for motion detection.
 * - `een.personDetectionEvent.v1`: Event for person detection.
 * - `een.vehicleDetectionEvent.v1`: Event for vehicle detection.
 */
export const SUPPORTED_EVENTS = [
  'een.motionDetectionEvent.v1',
  'een.personDetectionEvent.v1',
  'een.vehicleDetectionEvent.v1',
];
