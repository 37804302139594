import { useAppStateStore, useMessagingStore } from '@/stores';

export default async function useNoSavedChangesRouterGuard(beforeEachCallback) {
  const messagingStore = useMessagingStore();
  const appStateStore = useAppStateStore();

  if (messagingStore.showNoSavedChangesModal) {
    messagingStore.unsavedChangesDialog.callback = () => {
      messagingStore.showNoSavedChangesModal = false;
      beforeEachCallback();
    };

    messagingStore.unsavedChangesDialog.cancelCallback = () => {
      if (appStateStore.tempRouterHistoryItems.length) {
        appStateStore.routerHistoryItems.push(
          appStateStore.tempRouterHistoryItems[0],
          appStateStore.tempRouterHistoryItems[1]
        );
      }
    };

    messagingStore.showCustomConfirmationDialog(messagingStore.unsavedChangesDialog);
    return;
  }

  await beforeEachCallback();
}
