import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Load the plugins
dayjs.extend(utc);
dayjs.extend(timezone);

export const SECONDS_TO_MILLISECONDS = 1000;

export interface ToEENTimestampParams {
  date: Date;
  timezone?: string;
  showMilliseconds?: boolean;
  hour12: boolean;
  showDate?: boolean;
  showTime?: boolean;
}

export interface DateFormatterParams {
  timezone?: string;
  showMilliseconds?: boolean;
  hour12?: boolean;
  showDate?: boolean;
  showTime?: boolean;
}

/**
 * Converts a given date to a formatted timestamp string based on the provided options.
 * @param params - The parameters for converting the date to a timestamp.
 * @param params.date - The date to be converted.
 * @param params.timezone - The timezone to convert the date to.
 * @param params.showMilliseconds - Whether to show milliseconds in the timestamp.
 * @param params.hour12 - Whether to use 12-hour time format.
 * @param params.showDate - Whether to show the date in the timestamp.
 * @param params.showTime - Whether to show the time in the timestamp.
 * @returns The formatted timestamp string.
 */
export function toEENTimestamp(params: ToEENTimestampParams) {
  const { date, timezone, showMilliseconds, hour12, showDate = true, showTime = true } = params;
  // Convert the date to the specified timezone
  const zonedDate = dayjs(date).tz(timezone);

  // Construct the format string
  let formatString = '';

  if (showDate) formatString += 'YYYY-MM-DD';
  if (showTime) {
    formatString += `${showDate ? ' ' : ''}hh:mm:ss`;
    if (showMilliseconds) formatString += '.SSS';
    if (hour12) {
      formatString += ' A';
    } else {
      formatString = formatString.replace('hh', 'HH');
    }
  }
  if (!formatString) {
    return '';
  }

  // Format the date
  return zonedDate.format(formatString);
}
