import { useAppStateStore, useCamerasStore, useHistoryBrowserStore, useRulesStore } from '@/stores';
import { Route } from 'vue-router';
import router from './router';
import _ from 'lodash';
import { t } from '@/plugins/i18n.ts';

interface BreadcrumbDetails {
  title: string;
}

type NavigationMethodType = 'go' | 'replace' | 'push' | null;

export default function useTopBarBreadcrumbs(lastNavigationMethod: NavigationMethodType, to: Route, from: Route) {
  const appStateStore = useAppStateStore();
  const hbStore = useHistoryBrowserStore();
  const camerasStore = useCamerasStore();
  const rulesStore = useRulesStore();
  const defaultName = to.name ? t(to.name) : '';

  if (lastNavigationMethod === 'go') {
    appStateStore.routerHistoryItems.pop();
  } else if (lastNavigationMethod === 'replace') {
    // if the last navigation method was replace, never update the breadcrumbs and push the new route to the history stack
    return;
  }

  // for first level routes, no need to show the breadcrumbs
  if (to.meta?.rootRoute) {
    appStateStore.routerHistoryItems = [to];
    appStateStore.changeTopBarBreadcrumbs(undefined);
    return;
  }

  if (!appStateStore.routerHistoryItems.length) {
    appStateStore.routerHistoryItems = [from];
  }

  appStateStore.routerHistoryItems.push(to);

  updateBreadcrumbs();

  async function updateBreadcrumbs() {
    const { title } = getDetails();
    const fromRoute = appStateStore.routerHistoryItems[appStateStore.routerHistoryItems.length - 2];
    appStateStore.changeTopBarBreadcrumbs({
      from:
        fromRoute?.meta?.tooltipText || fromRoute?.name
          ? `${t('Back to')} ${fromRoute.meta?.tooltipText ?? fromRoute.name}`
          : '',
      title,
      backEvent: () => {
        appStateStore.routerHistoryItems.pop();
        router.go(-1);
      },
    });
  }

  /**
   * this function is specifically for history browser routes to handle case for single camera, multi-camera view and hb+live
   *
   * @param toRoute
   * @returns
   */
  function handleHistoryBrowserCase(): BreadcrumbDetails {
    if (hbStore.customOrder && hbStore.customOrder?.length > 1) {
      if (!hbStore.customOrder.toString().includes('live')) {
        return { title: t('Multi-Camera Playback') };
      } else return { title: t('History browser + Live view') };
    } else {
      hbStore.customOrder = hbStore.cameraIds;
      let camera;
      if (camerasStore.pagedCameras.length) {
        camera = camerasStore.pagedCameras.find((camera) => camera.id === hbStore.cameraIds[0]);
      }
      if (camerasStore.allCamerasByCustomParam.length) {
        camera = camerasStore.allCamerasByCustomParam.find((camera) => camera.id === hbStore.cameraIds[0]);
      }
      return {
        title: camera?.name ?? '',
      };
    }
  }

  function getDetails(): BreadcrumbDetails {
    const resourceId = to.params?.id;
    const resourceName = to.params?.name;

    switch (to.name) {
      case 'History Browser': {
        return handleHistoryBrowserCase();
      }

      case 'Add camera': {
        return {
          title: resourceName ? `${t('Add camera')} • ${resourceName}` ?? defaultName : defaultName,
        };
      }

      case 'Edit rule': {
        return {
          title:
            resourceId && rulesStore.currentRuleName
              ? `${t('Edit rule')} • ${rulesStore.currentRuleName}`
              : defaultName,
        };
      }

      case 'FaceSearch': {
        return {
          title: `${t('VideoSearch')}  • ${t('Face')}`,
        };
      }

      /**
       * add the route names which does not require auto update of breadcrumbs title from useTopBarBreadcrumbs custom hook
       * and can be handled by the route component itself
       */
      case 'Video':
      case 'User settings':
      case 'Role settings':
      case 'Location': {
        return {
          title: '',
        };
      }

      default:
        return { title: defaultName };
    }
  }
}
