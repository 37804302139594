import { ref, Ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';

import api from '@eencloud/eewc-components/src/service/api';
import { EventFieldValues } from '@eencloud/eewc-components/src/service/api-types/events';

import { QueryKeys } from '@/queries';

export const useGetDeviceSupportedEvents = (deviceId: Ref<string>, enabled: Ref<boolean> = ref(true)) => {
  return useQuery<EventFieldValues | undefined, Error>({
    queryKey: [QueryKeys.GET_DEVICE_SUPPORTED_EVENTS, deviceId.value],
    queryFn: () => api.fetchEventFieldValues(deviceId.value) as unknown as EventFieldValues,
    enabled: enabled,
  });
};
