import api from '@eencloud/eewc-components/src/service/api';
import { ApiPtzMove, PtzZoomStatus } from '@eencloud/eewc-components/src/service/api-types';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const usePtzStore = defineStore('ptz', function () {
  const ptzZoomStatuses = ref<PtzZoomStatus[]>([]);

  async function getPTZLocation(cameraId: string) {
    try {
      const ptzCamera = ptzZoomStatuses.value.find((ptz) => ptz.cameraId === cameraId);
      if (ptzCamera) {
        const { cameraId, ...ptzPosition } = ptzCamera;
        return ptzPosition;
      } else {
        return await api.getPTZPosition(cameraId);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function movePTZ(payload: { request: ApiPtzMove; cameraId: string }) {
    try {
      await api.movePTZ({
        cameraId: payload.cameraId,
        movementData: payload.request,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function updatePtzStatus(status: PtzZoomStatus) {
    // ToDo - in future when v2 websocket api will be not used, then we should not make the below api call.
    // reason - in v2 api, the WS PTZ event returns position (X, Y, Z) value which does not work with V3 PTZ position apis.
    const position = await api.getPTZPosition(status.cameraId);
    if (!position) {
      return;
    }
    const exists = ptzZoomStatuses.value.some((ptz) => ptz.cameraId === status.cameraId);
    if (exists) {
      ptzZoomStatuses.value = ptzZoomStatuses.value.filter((p) => p.cameraId !== status.cameraId);
    }

    ptzZoomStatuses.value = ptzZoomStatuses.value.concat([{ cameraId: status.cameraId, ...position }]);
  }

  return {
    getPTZLocation,
    movePTZ,
    updatePtzStatus,
    ptzZoomStatuses,
  };
});
