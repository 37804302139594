import api from '@eencloud/eewc-components/src/service/api';
import {
  ApiCameraSettings,
  CameraIOSettingsQueryParams,
  CameraSettingsResponse,
  PortPatch,
  Event,
  PortGet,
} from '@eencloud/eewc-components/src/service/api-types';
import { defineStore } from 'pinia';
import { ref } from 'vue';

type CamereIOSettings = {
  cameraId: string;
  ports: PortGet[];
};

export const useCamerasSettingsStore = defineStore('cameraSettings', () => {
  const camerasSettings = ref<Record<string, CameraSettingsResponse | undefined>>({});
  const cameraIOSettings = ref<CamereIOSettings[]>([]);
  const loading = ref(false);

  async function fetchCameraSettings(cameraId: string): Promise<CameraSettingsResponse | undefined> {
    // Verify if camera settings are already in the cache and if the settings have been updated
    if (camerasSettings.value[cameraId]) {
      return camerasSettings.value[cameraId];
    }

    const params = {
      // include: 'schema', Api crash when include schema
      include: 'proposedValues',
    };

    try {
      loading.value = true;
      // Make the API call if the settings are not cached
      const cameraSettingsResponse = await api.fetchCameraSettings(cameraId, params);
      if (cameraSettingsResponse) {
        camerasSettings.value[cameraId] = cameraSettingsResponse;
        return cameraSettingsResponse;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.value = false;
    }
  }

  async function updateCameraSettings(cameraId: string, payload: ApiCameraSettings) {
    return await api.patchCameraSettings(cameraId, { data: payload });
  }

  async function fetchIOSettings(cameraId: string, params: CameraIOSettingsQueryParams = {}) {
    cameraIOSettings.value = cameraIOSettings.value.filter((cameraIOSetting) => cameraIOSetting.cameraId !== cameraId);
    const res = await api.fetchCameraIOSettings(cameraId, params);
    if (res?.results) {
      cameraIOSettings.value.push({ cameraId, ports: res?.results });
      return res;
    }
  }

  async function updateIOSettings(cameraId: string, params: PortPatch & { portId: string }[]) {
    const statuses = await Promise.all(
      params.map((param) => {
        const { portId, ...payload } = param;
        return api.updateCameraIOSettings(cameraId, portId, { ...payload, id: undefined, type: undefined });
      })
    );
    const allSuccess = statuses.every((status) => status === 204);
    if (allSuccess) {
      const cameraIO = cameraIOSettings.value.find((cameraIOSetting) => cameraIOSetting.cameraId === cameraId);
      cameraIO?.ports.forEach((port) => {
        const updatedPort = params.find((param) => param.portId === port.id);
        if (updatedPort) {
          port.status = { state: updatedPort.status?.state };
        }
      });

      return allSuccess;
    }
  }

  function updateCameraIOStatus(event: Event) {
    if (!event.data.length) return;
    const index = cameraIOSettings.value.findIndex((cameraIOSetting) => cameraIOSetting.cameraId === event.actorId);
    if (index !== -1) {
      const newCameraIOSettings = cameraIOSettings.value[index]?.ports.map((port) => {
        if (port.id === event.data[0].portId && port.type === event.data[0].ioType) {
          return {
            ...port,
            status: { state: event.endTimestamp === null ? 'active' : 'idle' },
          };
        }
        return port;
      });
      cameraIOSettings.value[index].ports = newCameraIOSettings;
    }
  }

  function processSSEResponse(event: Event) {
    switch (event.type) {
      case 'een.deviceIOEvent.v1':
        updateCameraIOStatus(event);
        break;
    }
  }

  return {
    camerasSettings,
    loading,
    fetchCameraSettings,
    updateCameraSettings,
    fetchIOSettings,
    updateIOSettings,
    processSSEResponse,
    cameraIOSettings,
  };
});
