const SensorsHistory = () => import('@/pages/Sensors/History/SensorsHistory.vue');
const SensorsGatewayAdd = () => import('@/pages/Sensors/Gateways/SensorsGatewayAdd.vue');
const SensorsGatewaySettings = () => import('@/pages/Sensors/Gateways/SensorsGatewaySettings.vue');
const SensorsAvailableDevices = () => import('@/pages/Sensors/Devices/SensorsAvailableDevices.vue');
const SensorsDeviceAdd = () => import('@/pages/Sensors/Devices/SensorDeviceAdd.vue');
const SensorsDeviceSettings = () => import('@/pages/Sensors/Devices/SensorsDeviceSettings.vue');
const SensorsValuesSettings = () => import('@/pages/Sensors/Values/SensorsValuesSettings.vue');

export default [
  {
    path: '/sensors/add/:guid/model/:model',
    name: 'Add sensor',
    component: SensorsDeviceAdd,
    meta: {
      auth: true,
    },
  },
  {
    path: '/sensors/add',
    name: 'Available sensors',
    component: SensorsAvailableDevices,
    meta: {
      auth: true,
    },
  },
  {
    path: '/sensors/devices/:id',
    name: 'Sensor settings',
    component: SensorsDeviceSettings,
    meta: {
      auth: true,
    },
  },
  {
    path: '/sensors',
    name: 'Measurements',
    component: SensorsHistory,
    meta: {
      auth: true,
      rootRoute: true,
      filterOption: true,
    },
  },
  {
    path: '/sensors/measurements/:id',
    name: 'Sensor measurements',
    component: SensorsValuesSettings,
    meta: {
      auth: true,
    },
  },
  {
    path: '/sensors/gateways/add',
    name: 'Add sensor gateway',
    component: SensorsGatewayAdd,
    meta: {
      auth: true,
    },
  },
  {
    path: '/sensors/gateways/:id',
    name: 'Sensor gateway settings',
    component: SensorsGatewaySettings,
    meta: {
      auth: true,
    },
  },
];
