import { onMounted, onUnmounted } from 'vue';

export default function useShortcuts(
  keyDownHandler: (event: KeyboardEvent) => void,
  keyUpHandler: (event: KeyboardEvent) => void
) {
  function onKeyPress(event: KeyboardEvent, handler: (event: KeyboardEvent) => void) {
    const target = event.target as HTMLElement;
    if (target?.matches('input') || target?.matches('textarea')) {
      return;
    }
    if (!handler) return;
    handler(event);
  }

  onMounted(() => {
    document.addEventListener('keyup', (event) => onKeyPress(event, keyUpHandler));
    document.addEventListener('keydown', (event) => onKeyPress(event, keyDownHandler));
  });

  onUnmounted(() => {
    document.removeEventListener('keyup', (event) => onKeyPress(event, keyUpHandler));
    document.addEventListener('keydown', (event) => onKeyPress(event, keyDownHandler));
  });
}
