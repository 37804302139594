import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useVSPersistedStateStore = defineStore(
  'videoSearchPersistedState',
  function () {
    const objectClassHistory = ref<Array<string>>([]);
    const faceMatchFeature = ref<boolean>(false);

    const actions = {
      setObjectClassHistory(history: string) {
        const MAX_OBJECT_CLASS_HISTORY_LENGTH = 4;
        const updatedObjectClassHistory = objectClassHistory.value;

        if (updatedObjectClassHistory.length >= MAX_OBJECT_CLASS_HISTORY_LENGTH) {
          updatedObjectClassHistory.shift();
        }
        updatedObjectClassHistory.push(history);
      },

      updateShowHiddenFaceMatchFeature(state: boolean) {
        faceMatchFeature.value = state;
      },
    };

    return {
      objectClassHistory,
      faceMatchFeature,
      ...actions,
    };
  },
  {
    persist: true,
  }
);
