import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import router from '@/service/router';

const doNotUpload = ['access_token=', 'password=']; // urls with these query paramaters will have everything removed after them before going to a breadcrumb.

if (import.meta.env.PROD) {
  Sentry.init({
    Vue,
    // this is added because backend version of sentry is below 21.9.0 and
    // it does not support this feature
    // we disable the feature to avoid crashes
    sendClientReports: false,
    dsn: 'https://7d8af248a0684f86a9661f5b94d5667b@sentry.eencloud.com/29',
    integrations: [Sentry.browserTracingIntegration({ router })],
    tracesSampleRate: 1,
    beforeSendTransaction: () => false, // transaction types are not supported by backend version of sentry + were causing many calls from the PreviewTiles in the Grid.
    beforeBreadcrumb(breadcrumb) {
      doNotUpload.forEach((string) => {
        if (breadcrumb.category === 'xhr' || breadcrumb.category === 'fetch') {
          // filter out passwords and tokens from successfull api calls
          const index = breadcrumb.data.url.indexOf(string);
          if (index !== -1) {
            breadcrumb.data.url = breadcrumb.data.url.substring(0, index + string.length);
          }
        } else if (
          breadcrumb.category === 'console' &&
          breadcrumb.data.arguments &&
          Array.isArray(breadcrumb.data.arguments) &&
          breadcrumb.data.arguments.length > 1 &&
          breadcrumb.data.arguments[1].config
        ) {
          // filter out passwords and tokens from unsuccessfull api calls
          const index = breadcrumb.data.arguments[1].config.url.indexOf(string);
          if (index !== -1 && !isNaN(index)) {
            breadcrumb.data.arguments[1].config.url = breadcrumb.data.arguments[1].config.url.substring(
              0,
              index + string.length
            );
          }
        }
      });
      return breadcrumb;
    },
  });
}

export default Sentry;
