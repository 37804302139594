import { defineStore } from 'pinia';
import api from '@eencloud/eewc-components/src/service/api';
import { ref } from 'vue';
import {
  ApiMultiCameraChannel,
  ApiMultiCameraUpdate,
  ApiMultiCameraWithIncludes,
  ApiPaginatedMultiCameraChannelResponse,
  ApiPaginatedMultiCameraResponse,
  GetMultiCameraParams,
  GetMultiCamerasParams,
  PaginationQueryParams,
} from '@eencloud/eewc-components/src/service/api-types';

export const useMultiCamerasStore = defineStore('multiCameras', () => {
  const dashboardCurrentPage = ref<ApiMultiCameraWithIncludes[]>([]);
  const loadingMultiCameras = ref<boolean | undefined>(undefined);
  const totalSize = ref<number | undefined>(0);

  const getters = {
    multiCameras: (multiCameraId: string) =>
      dashboardCurrentPage.value.filter((multiCamera) => multiCamera.id === multiCameraId),
    multiCamera: (multiCameraId: string) =>
      dashboardCurrentPage.value.find((multiCamera) => multiCamera.id === multiCameraId),
  };

  const actions = {
    async getMultiCamera(params: GetMultiCameraParams, id: string) {
      return await api.getMultiCamera(id, params);
    },

    async getMultiCameras(params: GetMultiCamerasParams) {
      return api.getMultiCameras(params);
    },

    async getMultiCamerasFromBridgeIds(bridgeIds: string[] | undefined) {
      loadingMultiCameras.value = true;
      try {
        let pageToken: string | undefined;
        const virtualCameraDirectBridge = 'null,'; // added null to bridgeIds to get cameraDirect cameras.
        let newValue: ApiMultiCameraWithIncludes[] = [];

        bridgeIds && bridgeIds.push(virtualCameraDirectBridge);
        const bridgeIdIn = { bridgeId__in: bridgeIds?.toString() };
        do {
          const data = await api.getMultiCameras({
            pageToken: pageToken,
            pageSize: 500,
            ...bridgeIdIn,
          });
          if (data) {
            totalSize.value = data.totalSize;
            pageToken = data.nextPageToken;
            newValue = newValue.concat(data.results as ApiMultiCameraWithIncludes[]);
          } else break;
        } while (pageToken !== '');
        dashboardCurrentPage.value = newValue;
      } catch (error) {
        console.log(error);
      }
      loadingMultiCameras.value = false;
    },

    async getAllMultiCamerasByCustomParam(params: GetMultiCamerasParams) {
      let multiCameraList: ApiMultiCameraWithIncludes[] = [];
      try {
        let pageToken: string | undefined;
        do {
          const data = (await api.getMultiCameras({
            pageToken: pageToken as string,
            pageSize: 500,
            include: params.include ? params.include.toString() : undefined,
            ...params,
          })) as ApiPaginatedMultiCameraResponse;
          if (data) {
            pageToken = data.nextPageToken;
            multiCameraList = [...multiCameraList, ...(data.results as ApiMultiCameraWithIncludes[])];
          } else break;
        } while (pageToken !== '');
      } catch (error) {
        console.error(error);
      }
      return multiCameraList;
    },

    async patchMultiCamera(payload: ApiMultiCameraUpdate, multiCameraId: string) {
      return await api.patchMultiCamera(multiCameraId, payload);
    },

    async getMultiCameraChannels(multiCameraId: string, params: PaginationQueryParams) {
      let results: ApiMultiCameraChannel[] = [];
      let nextPageToken = '';
      let prevPageToken = '';
      let totalSize = 0;
      try {
        const data = await api.getMultiCameraChannels(multiCameraId, params);
        if (data) {
          nextPageToken = data?.nextPageToken ?? '';
          prevPageToken = data.prevPageToken ?? '';
          totalSize = data.totalSize ?? 0;
          results = data.results ?? [];
        }
      } catch (error) {
        console.log(error);
      }
      return {
        nextPageToken,
        prevPageToken,
        totalSize,
        results,
      };
    },

    async createTunnel(cameraId: string) {
      return await api.createDeviceTunnel(cameraId, 'multiCameras');
    },

    async deleteTunnel(cameraId: string) {
      return await api.deleteDeviceTunnel(cameraId, 'multiCameras');
    },

    async getPagedMulticameras(params: GetMultiCamerasParams) {
      let multicameras: ApiMultiCameraWithIncludes[] = [];
      let nextPageToken = '';
      let prevPageToken = '';
      let totalSize = 0;
      try {
        const res = await api.getMultiCameras(params);
        if (res) {
          const data = res as ApiPaginatedMultiCameraResponse;
          nextPageToken = data?.nextPageToken ?? '';
          prevPageToken = data?.prevPageToken ?? '';
          totalSize = data.totalSize ?? 0;
          multicameras = data.results as ApiMultiCameraWithIncludes[];
        }
      } catch (error) {
        console.error(error);
      }
      return { multicameras, totalSize, nextPageToken, prevPageToken };
    },
  };

  return {
    ...actions,
    ...getters,
    dashboardCurrentPage,
    loadingMultiCameras,
    totalSize,
  };
});
