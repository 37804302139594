import api from '@eencloud/eewc-components/src/service/api';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { TagGetResponse, TagsRequest } from '@eencloud/eewc-components/src/service/api-types';
import { IntersectionStateChanger } from '@eencloud/eewc-components/src/utils/types';

export const useTagsStore = defineStore('tags', function () {
  const pagedTags = ref<TagGetResponse[]>([]);
  const pagedTagsNextPageToken = ref<string | undefined>(undefined);
  const pagedTagsLoading = ref<boolean>(false);
  const pagedTagsInfiniteState = ref<IntersectionStateChanger>();

  const actions = {
    async getPagedTags(params: TagsRequest) {
      let tags: TagGetResponse[] = [];
      let nextPageToken = '';
      let totalSize = 0;
      try {
        const data = await api.getTags(params);
        if (data) {
          nextPageToken = data?.nextPageToken ?? '';
          totalSize = data.totalSize ?? 0;
          tags = data.results as TagGetResponse[];
        }
      } catch (error) {
        console.error(error);
      }
      return { tags, totalSize, nextPageToken };
    },

    resetPagedTags() {
      pagedTags.value = [];
      pagedTagsNextPageToken.value = undefined;
      if (pagedTagsInfiniteState.value) pagedTagsInfiniteState.value.reset();
    },
  };

  return {
    ...actions,
    pagedTags,
    pagedTagsNextPageToken,
    pagedTagsLoading,
  };
});
