import { Ref, computed, reactive } from 'vue';
import { t } from '@/plugins/i18n';

import { useUsersStore } from '@/stores';
import router from '@/service/router';

import { ROUTE_PARAM_IDS as VSP_ROUTE_PARAM_IDS, ROUTE_NAMES as VSP_ROUTE_NAMES } from '../constants';

function useVSPNavigation(VSPActiveRoute: Ref<string>) {
  const usersStore = useUsersStore();

  const VSP_SUB_ROUTES = Object.values(VSP_ROUTE_NAMES);

  function getVSPSubmenuItems() {
    const permissions = usersStore.permissions;

    const isActiveSearchRoute = [VSP_ROUTE_NAMES.vspMap, VSP_ROUTE_NAMES.search].includes(VSPActiveRoute.value);

    const DEFAULT_ITEMS = [
      {
        id: VSP_ROUTE_PARAM_IDS.summary,
        name: t(VSP_ROUTE_NAMES.summary),
        active: VSPActiveRoute.value === VSP_ROUTE_NAMES.summary,
      },
      {
        id: VSP_ROUTE_PARAM_IDS.search,
        name: t(VSP_ROUTE_NAMES.search),
        active: isActiveSearchRoute,
      },
      {
        id: VSP_ROUTE_PARAM_IDS.alerts,
        name: t(VSP_ROUTE_NAMES.alerts),
        active: VSPActiveRoute.value === VSP_ROUTE_NAMES.alerts,
      },
    ];

    const isActiveVehicleListRoute = [
      VSP_ROUTE_NAMES.vehicleList,
      VSP_ROUTE_NAMES.singleVehicleList,
      VSP_ROUTE_NAMES.vehicleUploadViaCSV,
      VSP_ROUTE_NAMES.addVehicle,
      VSP_ROUTE_NAMES.addVehicleFromSearch,
      VSP_ROUTE_NAMES.editVehicle,
    ].includes(VSPActiveRoute.value);

    const VEHICLE_LIST = {
      id: VSP_ROUTE_PARAM_IDS.vehicleList,
      name: t(VSP_ROUTE_NAMES.vehicleList),
      active: isActiveVehicleListRoute,
    };

    const isActiveRulesRoute = [VSP_ROUTE_NAMES.rules, VSP_ROUTE_NAMES.addRule, VSP_ROUTE_NAMES.editRule].includes(
      VSPActiveRoute.value
    );

    const RULE = {
      id: VSP_ROUTE_PARAM_IDS.rules,
      name: t(VSP_ROUTE_NAMES.rules),
      active: isActiveRulesRoute,
    };

    if (permissions?.viewVSPRule) {
      if (permissions.viewVehicleList) {
        return [...DEFAULT_ITEMS, RULE, VEHICLE_LIST];
      }

      return [...DEFAULT_ITEMS, RULE];
    }

    if (permissions?.viewVehicleList) {
      return [...DEFAULT_ITEMS, VEHICLE_LIST];
    }

    return DEFAULT_ITEMS;
  }

  const VSPNavigationData = reactive({
    items: computed(() => {
      return (
        usersStore.permissions?.viewVSP && {
          icon: '$icon_vsp',
          name: t('VSP'),
          active: VSP_SUB_ROUTES.includes(VSPActiveRoute.value) && router.currentRoute.meta?.vspSubRoute,
          route: 'Search',
          subItems: getVSPSubmenuItems(),
        }
      );
    }),
  });

  const handleVSPChildrenRoutes = (id: string) => {
    let targetRoute = '';
    switch (id) {
      case 'summary':
        targetRoute = '/vsp/summary';
        break;

      case 'vehicleList':
        targetRoute = '/vsp/vehicleList';
        break;

      case 'search':
        targetRoute = '/vsp/search';
        break;

      case 'rules':
        targetRoute = '/vsp/rules';
        break;

      case 'alerts':
        targetRoute = '/vsp/alerts';
        break;

      default:
        return false;
    }

    if (router.currentRoute.path !== targetRoute) {
      router.push(targetRoute);
    }

    return true;
  };

  return { VSPNavigationData, handleVSPChildrenRoutes, VSP_SUB_ROUTES };
}

export default useVSPNavigation;
