import { computed, ref, watch } from 'vue';
import { defineStore } from 'pinia';
import dayjs from 'dayjs';
import APIList from '@eencloud/eewc-components/src/service/api';
import {
  LPREventV1,
  LPREventsQueryParams,
  LPRActorType,
  LPRSummaryAggregate,
  LPREventsSummaryQueryParams,
  LPREntityDescriptor,
  ApiCameraWithIncludes,
  LPREventsResponse,
} from '@eencloud/eewc-components/src/service/api-types';
import { Item } from '@eencloud/eewc-components/src/components/dropdowns/types';

import usePagination from '@/service/usePagination';

import { useAccountStore } from './account';

import { UNKNOWN_EVENT_COUNT } from '@/pages/VSP/constants';

/**
 * Constants
 */

const DEFAULT_INCLUDES = [
  'data.een.lprAccessType.v1',
  'data.een.croppedFrameImageUrl.v1',
  'data.een.display.v1',
  'data.een.fullFrameImageUrl.v1',
  'data.een.lprDetection.v1',
  'data.een.ObjectDetection.v1',
  'data.een.userData.v1',
  'data.een.vehicleAttributes.v1',
];

const currentDateTime = new Date();
const oneDayBeforeDateTime = dayjs(currentDateTime).subtract(1, 'days').toDate();

const INITIAL_FILTERS = {
  licensePlate: '',
  dateRange: [oneDayBeforeDateTime.getTime(), currentDateTime.getTime()],
  makes: [],
  colors: [],
  vehicleTypes: [],
  sites: [],
  cameras: [],
  directions: [],
  userData: [],
  accessTypes: [],
};

const mapSidebarEvents = ref<LPREventsResponse | null>(null);
const mapSidebarScrollPosition = ref<number>(0);
const isMapSidebarThumbnailClicked = ref<boolean>(false);

type CameraGroupEventsResponse = {
  camera: string[];
  response: LPREventsResponse;
};

/**
 * Component
 */

export const useLPREventsStore = defineStore('lprEvents', function () {
  // Stores
  const accountStore = useAccountStore();

  const isSiteGroupingEnabled = computed(() => accountStore.accountCapabilities?.locationGroups ?? false);

  // Events
  const events = ref<Array<LPREventV1>>([]);
  const isLoadingEvents = ref(false);
  const eventsResponseOfAllCamera = ref<LPREventsResponse | null>(null);

  const filterEvents = ref<LPREventsQueryParams>({});

  // Field values
  const isLoadingEventsFieldValues = ref(false);
  const accessTypes = ref<LPREntityDescriptor[]>([]);
  const cameraIDs = ref<Array<string>>([]);
  const colors = ref<LPREntityDescriptor[]>([]);
  const directions = ref<LPREntityDescriptor[]>([]);
  const siteIDs = ref<Array<string>>([]);
  const makes = ref<LPREntityDescriptor[]>([]);
  const plateRegions = ref<LPREntityDescriptor[]>([]);
  const bodyTypes = ref<LPREntityDescriptor[]>([]);
  const eventResponseGroupData = ref<CameraGroupEventsResponse[]>([]);

  const selectedDateRange = ref<number[]>(INITIAL_FILTERS.dateRange);
  const licensePlate = ref<string>(INITIAL_FILTERS.licensePlate);
  const selectedVehicleTypes = ref<Item[]>(INITIAL_FILTERS.vehicleTypes);
  const selectedColors = ref<Item[]>(INITIAL_FILTERS.colors);
  const selectedMakes = ref<Item[]>(INITIAL_FILTERS.makes);
  const selectedSites = ref<Item[]>(INITIAL_FILTERS.sites);
  const selectedCameras = ref<Item[]>(INITIAL_FILTERS.cameras);
  const selectedDirections = ref<Item[]>(INITIAL_FILTERS.directions);
  const userData = ref<string[]>(INITIAL_FILTERS.userData);
  const selectedAccessTypes = ref<Item[]>(INITIAL_FILTERS.accessTypes);

  // Pagination
  const { getPageToken, paginationParams, updatePaginationData } = usePagination();
  const nextPageToken = ref<string>('');
  const prevPageToken = ref<string>('');
  const currentPageToken = ref<string>('');
  const eventsTotalSize = ref<number>(0);
  const pageSize = ref(25);
  const currentPage = ref(1);
  const scrollYPosition = ref(0);

  // To handle preset text change in 'Maps' page.
  const presetText = ref<string>('');
  const shouldShowMapSidebar = ref(false);

  const getPaginatedEvents = async (params?: LPREventsQueryParams) => {
    const queryParams: LPREventsQueryParams = {
      ...params,
      ...filterEvents.value,
      pageSize: paginationParams.pageSize,
      include: DEFAULT_INCLUDES.toString(),
    };

    if (!params && currentPageToken.value) {
      queryParams.pageToken = currentPageToken.value;
      queryParams.pageSize = pageSize.value;
    }

    currentPageToken.value = queryParams.pageToken ?? '';
    pageSize.value = queryParams.pageSize;
    currentPage.value = paginationParams.page;

    try {
      isLoadingEvents.value = true;

      const data = await APIList.fetchLPREvents(queryParams);

      events.value = data?.results || [];
      eventsTotalSize.value = data.totalSize ?? 0;
      nextPageToken.value = data?.nextPageToken ?? '';
      prevPageToken.value = data?.prevPageToken ?? '';

      eventsResponseOfAllCamera.value = data;
    } catch {
      // Error
      events.value = [];
      eventsTotalSize.value = 0;
      nextPageToken.value = '';
      prevPageToken.value = '';

      eventsResponseOfAllCamera.value = null;
    } finally {
      isLoadingEvents.value = false;
    }
  };

  const fetchEventsByCameraId = async (cameras: ApiCameraWithIncludes[] | object, groupCameras?: boolean) => {
    const queryParams = {
      ...filterEvents.value,
      include: DEFAULT_INCLUDES.toString(),
      pageSize: 50,
    };

    isLoadingEvents.value = true;

    if (!groupCameras) {
      const eventsResponse: PromiseSettledResult<LPREventsResponse>[] = await Promise.allSettled(
        cameras.reduce((eventsData, camera) => {
          const queryParamsWithCameraFilter = { ...queryParams, actor: `camera:${camera.id}` };

          eventsData.push(APIList.fetchLPREvents(queryParamsWithCameraFilter));
          return eventsData;
        }, [])
      );

      const lprEvents: LPREventsResponse[] = eventsResponse.reduce(
        (eventsData, eventResponse: PromiseSettledResult<LPREventV1>) => {
          if (eventResponse.status === 'fulfilled') {
            eventsData.push(eventResponse.value);
          }
          return eventsData;
        },
        []
      );
      eventsResponses.value = lprEvents;
    } else if (typeof cameras === 'object' && !Array.isArray(cameras)) {
      const cameraIdFilter = Object.keys(cameras).reduce((cameraIds, key) => {
        const ids = cameras[key].map((cam) => cam.id);
        cameraIds.push(ids);
        return cameraIds;
      }, []);

      const eventsResponse: PromiseSettledResult<LPREventsResponse>[] = await Promise.allSettled(
        cameraIdFilter.reduce((eventsData, cameraIds) => {
          const actor = cameraIds.map((id) => `camera:${id}`);
          const queryParamsWithCameraFilter = { ...queryParams, actor: actor.toString() };
          eventsData.push(APIList.fetchLPREvents(queryParamsWithCameraFilter));
          return eventsData;
        }, [])
      );

      const eventResponseGroup: CameraGroupEventsResponse[] = [];

      const lprEvents: LPREventsResponse[] = eventsResponse.reduce(
        (eventsData, eventResponse: PromiseSettledResult<LPREventV1>, i) => {
          if (eventResponse.status === 'fulfilled') {
            eventsData.push(eventResponse.value);
            eventResponseGroup.push({ response: eventResponse.value, camera: cameraIdFilter[i] });
          } else {
            eventResponseGroup.push({ response: null, camera: cameraIdFilter[i] });
          }
          return eventsData;
        },
        []
      );

      eventsResponses.value = lprEvents;
      eventResponseGroupData.value = eventResponseGroup;
    }

    isLoadingEvents.value = false;
  };

  const eventsResponses = ref<LPREventsResponse[]>([]);

  const getEventsFieldValues = async () => {
    try {
      isLoadingEventsFieldValues.value = true;

      const response = await APIList.fetchLPREventsFieldValues();

      const {
        accessType = [],
        actor = [],
        color = [],
        direction = [],
        make = [],
        plateRegion = [],
        bodyType = [],
      } = response;

      accessTypes.value = accessType;
      colors.value = color;
      directions.value = direction;
      makes.value = make;
      plateRegions.value = plateRegion;
      bodyTypes.value = bodyType;

      const cameraIds: Array<string> = [];
      const siteIds: Array<string> = [];

      actor.forEach((actor) => {
        const doesIDExist = actor.id;

        // Filter by truthy id and camera type
        if (doesIDExist && actor.type === LPRActorType.Camera) {
          cameraIds.push(actor.id as string);
          // Filter by truthy id and site type
        } else if (doesIDExist && actor.type === LPRActorType.Location) {
          siteIds.push(actor.id as string);
        }
      });

      cameraIDs.value = cameraIds;
      siteIDs.value = siteIds;
    } catch {
      // Error
      accessTypes.value = [];
      cameraIDs.value = [];
      colors.value = [];
      directions.value = [];
      siteIDs.value = [];
      makes.value = [];
      plateRegions.value = [];
      bodyTypes.value = [];
    } finally {
      isLoadingEventsFieldValues.value = false;
    }
  };

  watch(
    () => [paginationParams.page, paginationParams.pageSize],
    ([newPage, newPageSize], [oldPage]) => {
      const pageToken = getPageToken(newPage, oldPage, nextPageToken.value, prevPageToken.value);

      const params: LPREventsQueryParams = {
        pageSize: newPageSize,
      };

      if (pageToken) {
        params.pageToken = pageToken;
      }

      getPaginatedEvents(params);
    }
  );

  // Aggregated events summmary
  const aggregatedEventsSummary = ref<Array<LPRSummaryAggregate>>([]);
  const isLoadingAggregatedEventsSummary = ref<boolean>(false);

  const getAggregatedEventsSummary = async (params: LPREventsSummaryQueryParams) => {
    const INCLUDES = ['lastHour', 'today', 'yesterday', 'last30Days', 'last7Days'];

    try {
      isLoadingAggregatedEventsSummary.value = true;

      const response = await APIList.fetchLPREventsSummary({
        ...params,
        include: INCLUDES.toString(),
      });

      aggregatedEventsSummary.value = response.results ?? [];
    } catch {
      // Error
      aggregatedEventsSummary.value = [];
    } finally {
      isLoadingAggregatedEventsSummary.value = false;
    }
  };

  // Events summary
  const eventsSummary = ref<Record<string, Omit<LPRSummaryAggregate, 'actorId'>>>({});
  const isLoadingEventsSummary = ref<boolean>(false);

  const getShortDurationEventsSummary = async (userTime: string) => {
    const INCLUDES = ['lastHour', 'today', 'yesterday'];

    let summary: Array<Omit<LPRSummaryAggregate, 'last30Days' | 'last7Days'>> = [];

    let nextPageToken: string | undefined;

    try {
      while (nextPageToken !== '') {
        const response = await APIList.fetchLPREventsSummary({
          include: INCLUDES.toString(),
          pageSize: 500,
          pageToken: nextPageToken as string,
          groupBy: 'actorId',
          userTime,
        });

        if (response) {
          summary = summary.concat(response.results ?? []);
          nextPageToken = response.nextPageToken;
        } else break;
      }
    } catch {
      // Error
    }

    return summary;
  };

  const getLongDurationEventsSummary = async (userTime: string) => {
    const INCLUDES = ['last30Days', 'last7Days'];

    let summary: Array<Pick<LPRSummaryAggregate, 'actorId' | 'last30Days' | 'last7Days'>> = [];

    let nextPageToken: string | undefined;

    try {
      while (nextPageToken !== '') {
        const response = await APIList.fetchLPREventsSummary({
          include: INCLUDES.toString(),
          pageSize: 500,
          pageToken: nextPageToken as string,
          groupBy: 'actorId',
          userTime,
        });

        if (response) {
          summary = summary.concat(response.results ?? []);
          nextPageToken = response.nextPageToken;
        } else break;
      }
    } catch {
      // Error
    }

    return summary;
  };

  const getEventsSummary = async (userTime: string) => {
    isLoadingEventsSummary.value = true;

    eventsSummary.value = {}; // Remove existing summary to prevent duplicates

    try {
      const summary = await Promise.allSettled([
        getShortDurationEventsSummary(userTime),
        getLongDurationEventsSummary(userTime),
      ]);

      const shortDurationSummary = summary[0].status === 'fulfilled' ? summary[0].value : [];

      const longDurationSummary = summary[1].status === 'fulfilled' ? summary[1].value : [];

      const map: Record<string, Omit<LPRSummaryAggregate, 'actorId'>> = {};

      shortDurationSummary.forEach((summary) => {
        const {
          actorId: cameraID,
          lastHour = UNKNOWN_EVENT_COUNT,
          today = UNKNOWN_EVENT_COUNT,
          yesterday = UNKNOWN_EVENT_COUNT,
        } = summary;

        if (cameraID) {
          map[cameraID] = { lastHour, yesterday, today };
        }
      });

      longDurationSummary.forEach((summary) => {
        const { actorId: cameraID, last30Days = UNKNOWN_EVENT_COUNT, last7Days = UNKNOWN_EVENT_COUNT } = summary;

        if (cameraID) {
          if (map[cameraID]) {
            map[cameraID].last7Days = last7Days;
            map[cameraID].last30Days = last30Days;
          } else {
            map[cameraID] = { last7Days, last30Days };
          }
        }
      });

      eventsSummary.value = map;
    } catch {
      // Pass
    }

    isLoadingEventsSummary.value = false;
  };

  const getEventImage = async (url: string): Promise<ArrayBuffer | undefined> => {
    return await APIList.getEventImageByUrl(url);
  };

  const colorsInfoMap = computed(() => {
    const map = colors.value.reduce<Record<string, string>>((acc, color) => {
      acc[color.value] = color.display;

      return acc;
    }, {});

    return map;
  });

  const vehicleTypesInfoMap = computed(() => {
    const map = bodyTypes.value.reduce<Record<string, string>>((acc, type) => {
      acc[type.value] = type.display;

      return acc;
    }, {});

    return map;
  });

  const makesInfoMap = computed(() => {
    const map = makes.value.reduce<Record<string, string>>((acc, make) => {
      acc[make.value] = make.display;

      return acc;
    }, {});

    return map;
  });

  const directionInfoMap = computed(() => {
    const map = directions.value.reduce<Record<string, string>>((acc, direction) => {
      acc[direction.value] = direction.display;

      return acc;
    }, {});

    return map;
  });

  return {
    accessTypes,
    aggregatedEventsSummary,
    bodyTypes,
    cameraIDs,
    colors,
    colorsInfoMap,
    currentPage,
    currentPageToken,
    DEFAULT_INCLUDES,
    directions,
    directionInfoMap,
    events,
    eventsResponseOfAllCamera,
    eventResponseGroupData,
    eventsResponses,
    eventsSummary,
    eventsTotalSize,
    fetchEventsByCameraId,
    filterEvents,
    getAggregatedEventsSummary,
    getEventImage,
    getEventsFieldValues,
    getEventsSummary,
    getPageToken,
    getPaginatedEvents,
    INITIAL_FILTERS,
    isLoadingAggregatedEventsSummary,
    isLoadingEvents,
    isLoadingEventsFieldValues,
    isLoadingEventsSummary,
    isMapSidebarThumbnailClicked,
    isSiteGroupingEnabled,
    licensePlate,
    siteIDs,
    makes,
    makesInfoMap,
    mapSidebarEvents,
    mapSidebarScrollPosition,
    nextPageToken,
    pageSize,
    paginationParams,
    plateRegions,
    presetText,
    prevPageToken,
    scrollYPosition,
    selectedAccessTypes,
    selectedCameras,
    selectedColors,
    selectedDateRange,
    selectedDirections,
    selectedSites,
    selectedMakes,
    selectedVehicleTypes,
    shouldShowMapSidebar,
    updatePaginationData,
    userData,
    vehicleTypesInfoMap,
  };
});
