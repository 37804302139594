<template>
  <div>
    <eewc-confirmation-dialog
      :show-dialog="messagingStore.confirmationDialog.showDialog"
      :icon-color="messagingStore.confirmationDialog.iconColor"
      :icon="messagingStore.confirmationDialog.icon"
      @closeDialog="messagingStore.resetConfirmationDialog()"
    >
      <template #title>
        {{ $tc(messagingStore.confirmationDialog.titleSlot) }}
      </template>
      <template #body>
        <!-- TODO: This text is not translated. We need a solution for translations with parameters. -->
        <span v-html="messagingStore.confirmationDialog.bodySlot" />
      </template>
      <template #buttonright>
        <eewc-button-common
          v-if="buttonRightLabel"
          :color="messagingStore.confirmationDialog[!reverseBtnOrder ? 'cancelBtnColor' : 'confirmBtnColor']"
          :loading="!reverseBtnOrder ? false : loading"
          :type="messagingStore.confirmationDialog[!reverseBtnOrder ? 'cancelBtnType' : 'confirmBtnType']"
          @click="
            !reverseBtnOrder
              ? confirmCancelButtonCallback()
              : confirmButtonCallback(messagingStore.confirmationDialog.id)
          "
        >
          {{ $t(buttonRightLabel) }}
        </eewc-button-common>
      </template>
      <template #buttonleft>
        <eewc-button-common
          v-if="!hideConfirmButton && buttonLeftLabel"
          :color="messagingStore.confirmationDialog[reverseBtnOrder ? 'cancelBtnColor' : 'confirmBtnColor']"
          :loading="reverseBtnOrder ? false : loading"
          :type="messagingStore.confirmationDialog[reverseBtnOrder ? 'cancelBtnType' : 'confirmBtnType']"
          @click="
            reverseBtnOrder
              ? confirmCancelButtonCallback()
              : confirmButtonCallback(messagingStore.confirmationDialog.id)
          "
        >
          {{ $t(buttonLeftLabel) }}
        </eewc-button-common>
      </template>
    </eewc-confirmation-dialog>
  </div>
</template>

<script>
import { useMessagingStore } from '@/stores';
import { ref, computed } from 'vue';

export default {
  name: 'App',
  setup() {
    const messagingStore = useMessagingStore();
    const loading = ref(false);

    const reverseBtnOrder = computed(() => messagingStore.confirmationDialog.reverseBtnOrder);
    const hideConfirmButton = computed(() => messagingStore.confirmationDialog.hideConfirmButton);
    const buttonLeftLabel = computed(
      () => messagingStore.confirmationDialog[reverseBtnOrder.value ? 'cancelBtnText' : 'buttonMessage']
    );
    const buttonRightLabel = computed(
      () => messagingStore.confirmationDialog[!reverseBtnOrder.value ? 'cancelBtnText' : 'buttonMessage']
    );

    async function confirmButtonCallback(itemId) {
      this.loading = true;
      await messagingStore.confirmationDialog.confirmButtonCallback(itemId); // make the delete http req
      this.loading = false;

      !messagingStore.confirmationDialog.doNotShowNotification &&
        messagingStore.addNotification({
          iconColor: 'positive',
          icon: '$icon_check_zero',
          title: messagingStore.confirmationDialog.msgType === 'remove' ? this.$t('Removed') : this.$t('Deleted'),
          body: `${messagingStore.confirmationDialog.name} ${this.$t(
            'has been successfully ' + (messagingStore.confirmationDialog.msgType === 'remove' ? 'removed' : 'deleted')
          )}`,
        });

      messagingStore.resetConfirmationDialog(); // remove the confirmation dialog from the screen
    }

    function confirmCancelButtonCallback() {
      messagingStore.confirmationDialog.confirmCancelButtonCallback &&
        messagingStore.confirmationDialog.confirmCancelButtonCallback();
      messagingStore.resetConfirmationDialog(); // remove the confirmation dialog from the screen
    }

    return {
      loading,
      messagingStore,
      confirmButtonCallback,
      confirmCancelButtonCallback,
      reverseBtnOrder,
      hideConfirmButton,
      buttonLeftLabel,
      buttonRightLabel,
    };
  },
};
</script>

<style></style>
