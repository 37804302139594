import otaClient from '@crowdin/ota-client';

/* eslint-disable no-undef */
const hash = '5c6b3bfd5a8f3762e06a767eq37';
const OTAConfig = { languageCode: 'en-US' };
// initialization of crowdin ota client
const client = new otaClient(hash, OTAConfig);

export async function listLanguages() {
  try {
    const res = await client.listLanguages();
    return res;
  } catch (error) {
    console.log(error);
  }
}

export async function downloadTranslations(lang: string) {
  try {
    const res = await client.getLanguageTranslations(lang);
    return res[0].content;
  } catch (error) {
    console.log(error);
  }
}
